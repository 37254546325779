
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BalToggle',
  inheritAttrs: false,
  emits: ['update:modelValue', 'toggle'],
  props: {
    name: { type: String, required: true },
    modelValue: { type: Boolean, default: false },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    color: {
      type: String,
      default: 'white',
      validator: (val: string): boolean => ['white'].includes(val)
    }
  },
  setup(props, { emit }) {
    /**
     * METHODS
     */
    function onClick(event) {
      if (!props.disabled) {
        emit('update:modelValue', event.target.checked);
        emit('toggle', event.target.checked);
      }
    }
    return {
      // methods
      onClick
    };
  }
});
