import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-xs text-gray-200 leading-none" }
const _hoisted_3 = { class: "flex items-center justify-between" }

import { configService } from '@/services/config/config.service';
// Composables
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
// Components
import WithdrawForm from '@/components/forms/pool_actions/WithdrawForm/WithdrawForm.vue';
import TradeSettingsPopover, {
  TradeSettingsContext
} from '@/components/popovers/TradeSettingsPopover.vue';
import { computed } from 'vue';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const { network } = configService;
const { pool, loadingPool, transfersAllowed } = usePoolTransfers();

const hasBptStaked = computed(
  () => pool.value && pool.value.farm && pool.value.farm.userBpt > 0
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(loadingPool) || !_unref(transfersAllowed))
      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
          key: 0,
          class: "h-96"
        }))
      : (_openBlock(), _createBlock(_component_BalCard, {
          key: 1,
          shadow: "xl",
          exposeOverflow: "",
          noBorder: ""
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(network).chainName), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('withdrawFromPool')), 1),
                _createVNode(TradeSettingsPopover, {
                  context: _unref(TradeSettingsContext).invest
                }, null, 8, ["context"])
              ]),
              (_unref(hasBptStaked))
                ? (_openBlock(), _createBlock(_component_BalAlert, {
                    key: 0,
                    title: "You have WPT staked in the farm",
                    description: "To withdraw your funds, you need to first unstake your WPT from the farm.",
                    type: "warning",
                    size: "sm",
                    class: "mt-2 mb-2"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(WithdrawForm, { pool: _unref(pool) }, null, 8, ["pool"])
          ]),
          _: 1
        }))
  ]))
}
}

})