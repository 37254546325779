
import { defineComponent, computed } from 'vue';

import { EXTERNAL_LINKS } from '@/constants/links';

import useFathom from '@/composables/useFathom';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers from '@/composables/useNumbers';

import useWeb3 from '@/services/web3/useWeb3';
import AppNavActivityBtn from '@/components/navs/AppNav/AppNavActivityBtn/AppNavActivityBtn.vue';
import AppNavAccountBtn from '@/components/navs/AppNav/AppNavAccountBtn.vue';
import AppNavClaimBtn from '@/embr/components/navs/AppNavClaimBtn.vue';
import AppNavEmbr from '@/embr/components/navs/AppNavEmbr.vue';

export default defineComponent({
  name: 'AppNavActions',

  components: {
    AppNavEmbr,
    AppNavAccountBtn,
    AppNavClaimBtn,
    AppNavActivityBtn
  },

  setup() {
    // COMPOSABLES
    const { upToSmallBreakpoint, upToLargeBreakpoint } = useBreakpoints();
    const { fNum } = useNumbers();
    const { trackGoal, Goals } = useFathom();
    const {
      connectWallet,
      account,
      toggleWalletSelectModal,
      isMainnet,
      isKovan,
      isPolygon,
      isArbitrum
    } = useWeb3();

    // COMPUTED
    const liquidityMiningSupported = computed(
      () =>
        isMainnet.value || isPolygon.value || isArbitrum.value || isKovan.value
    );

    // METHODS
    function onClickConnect() {
      trackGoal(Goals.ClickNavConnectWallet);
    }

    return {
      // computed
      liquidityMiningSupported,
      account,
      upToSmallBreakpoint,
      upToLargeBreakpoint,
      // methods
      fNum,
      onClickConnect,
      connectWallet,
      toggleWalletSelectModal,
      // constants
      EXTERNAL_LINKS
    };
  }
});
