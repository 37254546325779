import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { FullPool, SubgraphSwap } from '@/services/balancer/subgraph/types';
import { getAddress } from '@ethersproject/address';
import useNumbers from '@/composables/useNumbers';
import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';
import { isStableLike } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import useWeb3 from '@/services/web3/useWeb3';
import { format, getUnixTime } from 'date-fns';
import { flatten, orderBy } from 'lodash';
import numeral from 'numeral';
import { GqlLge } from '@/embr/services/embr/embr-types';
import useLge from '@/embr/lbp/composables/useLge';
import usePoolSwapsQuery from '@/composables/queries/usePoolSwapsQuery';
import useAllPoolSwapsQuery from '@/embr/composables/queries/useAllPoolSwapsQuery';

type Props = {
  lge: GqlLge;
  pool: FullPool;
};


export default _defineComponent({
  props: {
    lge: { type: null, required: true },
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  lge: GqlLge;
  pool: FullPool;
}) {

const props = __props

const { appNetworkConfig } = useWeb3();

const {
  isBeforeStart,
  isAfterEnd,
  startDateTimeFormatted,
  endDateTimeFormatted,
  launchToken,
  collateralToken,
  startsAt,
  poolLaunchToken
} = useLge(props.lge, props.pool);

const { fNum } = useNumbers();
const router = useRouter();
const { darkMode } = useDarkMode();
const { upToLargeBreakpoint } = useBreakpoints();
const { tokens, priceFor } = useTokens();
const { isWalletReady } = useWeb3();

const { data: swaps, isLoading } = useAllPoolSwapsQuery(
  ref(props.lge.id),
  ref(getUnixTime(startsAt.value))
);

function loadMoreSwaps() {
  //swapsQuery.fetchNextPage.value();
}

const columns = ref<ColumnDefinition<SubgraphSwap>[]>([
  {
    name: 'Time',
    id: 'timestamp',
    accessor: 'timestamp',
    sortKey: 'timestamp',
    width: 250
  },
  {
    name: 'Type',
    id: 'type',
    accessor: 'type',
    sortKey: 'type',
    width: 150
  },
  {
    name: 'Input',
    id: 'input',
    accessor: 'input',
    sortKey: 'input',
    width: 150
  },
  {
    name: 'Output',
    id: 'output',
    accessor: 'output',
    sortKey: 'output',
    width: 150
  },
  {
    name: `${poolLaunchToken.value?.symbol} Price`,
    id: 'price',
    accessor: 'price',
    sortKey: 'price',
    width: 150
  },
  {
    name: 'Wallet',
    id: 'wallet',
    accessor: 'wallet',
    sortKey: 'wallet',
    width: 150
  }
]);

const data = computed(() => {
  return orderBy(swaps.value || [], 'timestamp', 'desc').map(swap => ({
    timestamp:
      format(swap.timestamp * 1000, 'MMM dd') +
      ' at ' +
      format(swap.timestamp * 1000, 'HH:mm'),
    type:
      swap.tokenOut === props.lge.tokenContractAddress.toLowerCase()
        ? 'Buy'
        : 'Sell',
    input: `${numeral(parseFloat(swap.tokenAmountIn)).format('0,0.[00]')} ${
      swap.tokenInSym
    }`,
    output: `${numeral(parseFloat(swap.tokenAmountOut)).format('0,0.[00]')} ${
      swap.tokenOutSym
    }`,
    price: `$${
      swap.tokenOut === props.lge.tokenContractAddress.toLowerCase()
        ? (
            (parseFloat(swap.tokenAmountIn) / parseFloat(swap.tokenAmountOut)) *
            priceFor(getAddress(swap.tokenIn))
          ).toFixed(4)
        : (
            (parseFloat(swap.tokenAmountOut) / parseFloat(swap.tokenAmountIn)) *
            priceFor(getAddress(swap.tokenOut))
          ).toFixed(4)
    }`,
    wallet: `${swap.userAddress.id.substr(0, 6)}...${swap.userAddress.id.substr(
      -4
    )}`
  }));
});

return (_ctx: any,_cache: any) => {
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: columns.value,
        data: _unref(data),
        "is-loading": _unref(isLoading),
        "skeleton-class": "h-64",
        sticky: "both",
        square: _unref(upToLargeBreakpoint),
        onLoadMore: loadMoreSwaps,
        isSortable: false,
        "is-paginated": _ctx.hasNextPage,
        isLoadingMore: _ctx.isLoadingMore
      }, null, 8, ["columns", "data", "is-loading", "square", "is-paginated", "isLoadingMore"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})