
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import useWeb3 from '@/services/web3/useWeb3';
import useTokenApproval from '@/composables/trade/useTokenApproval';

const ETH_BUFFER = 0.1;

export default defineComponent({
  components: { BalIcon, SelectTokenModal },
  props: {
    tokenAmountInput: {
      type: String,
      required: true
    },
    tokenWeightInput: {
      type: String,
      required: true
    },
    tokenAddressInput: {
      type: String,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'tokenAddressChange',
    'tokenAmountChange',
    'tokenWeightChange',
    'tokenDelete',
    'tokenApproved'
  ],

  setup(props, { emit }) {
    const { tokens, balances, injectTokens } = useTokens();
    const { fNum, toFiat } = useNumbers();
    const { appNetworkConfig } = useWeb3();
    const { tokenAmountInput, tokenAddressInput } = toRefs(props);
    const {
      approving,
      approved,
      isUnlockedV2,
      approveV2,
      isLoading: isLoadingApprovals
    } = useTokenApproval(tokenAddressInput, tokenAmountInput, tokens);

    const tokenValue = computed(() =>
      toFiat(tokenAmountInput.value, tokenAddressInput.value)
    );

    const tokenSymbol = computed(() => {
      const token = tokens.value[tokenAddressInput.value];
      const symbol = token ? token.symbol : '';
      return symbol;
    });

    const tokenDecimals = computed(() => {
      const decimals = tokens.value[tokenAddressInput.value]
        ? tokens.value[tokenAddressInput.value].decimals
        : 18;
      return decimals;
    });

    const isInRate = ref(true);
    const modalSelectTokenType = ref('input');
    const modalSelectTokenIsOpen = ref(false);

    function handleMax(): void {
      const balance = balances.value[tokenAddressInput.value] || '0';
      const balanceNumber = parseFloat(balance);
      const maxAmount =
        tokenAddressInput.value !== NATIVE_ASSET_ADDRESS
          ? balance
          : balanceNumber > ETH_BUFFER
          ? (balanceNumber - ETH_BUFFER).toString()
          : '0';
      handleAmountChange(maxAmount);
    }

    function handleAmountChange(value: string): void {
      emit('tokenAmountChange', value);
    }

    function handleWeightChange(value: string): void {
      emit('tokenWeightChange', value);
    }

    function handleSelectToken(address: string): void {
      emit('tokenAddressChange', address);
      injectTokens([address]);
    }

    function handleTokenDelete(): void {
      emit('tokenDelete');
    }

    function toggleRate(): void {
      isInRate.value = !isInRate.value;
    }

    function openModalSelectToken(type: string): void {
      modalSelectTokenIsOpen.value = true;
      modalSelectTokenType.value = type;
    }

    const balanceLabel = computed(
      () => balances.value[tokenAddressInput.value]
    );

    watch(isUnlockedV2, newVal => {
      if (newVal) {
        emit('tokenApproved', props.tokenAddressInput);
      }
    });

    return {
      fNum,
      handleMax,
      balanceLabel,
      handleAmountChange,
      toggleRate,
      tokenValue,
      tokenSymbol,
      modalSelectTokenIsOpen,
      openModalSelectToken,
      handleSelectToken,
      handleWeightChange,
      handleTokenDelete,
      tokenDecimals,
      nativeAssetAddress: appNetworkConfig.nativeAsset.address,
      approved,
      approving,
      isLoadingApprovals,
      isUnlockedV2,
      approveV2
    };
  }
});
