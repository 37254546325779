
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppIcon',

  setup() {
    return {};
  }
});
