import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_2 = ["innerHTML"]

import { toRef, computed } from 'vue';
import { usePool } from '@/composables/usePool';
import { FullPool } from '@/services/balancer/subgraph/types';
import useConfig from '@/composables/useConfig';
import useTokens from '@/composables/useTokens';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { isWstETHPool } = usePool(toRef(props, 'pool'));
const { networkConfig } = useConfig();
const { getToken } = useTokens();

/**
 * COMPUTED
 */
const stETH = computed(() => getToken(networkConfig.addresses.stETH));
const wstETH = computed(() => getToken(networkConfig.addresses.wstETH));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_unref(isWstETHPool))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: {
        name: 'trade',
        params: {
          assetIn: _unref(stETH).address,
          assetOut: _unref(wstETH).address
        }
      },
          class: "text-xs text-gray-200 underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('wrapInstruction', [_unref(stETH).symbol, _unref(wstETH).symbol])), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_BalTooltip, null, {
          activator: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "info",
              size: "xs",
              class: "text-gray-400 ml-2"
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.$t('wrapStEthTooltip')
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})