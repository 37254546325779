import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-center justify-between" }
const _hoisted_2 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TradeSettingsPopover = _resolveComponent("TradeSettingsPopover")!
  const _component_TradePair = _resolveComponent("TradePair")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_SuccessOverlay = _resolveComponent("SuccessOverlay")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_TradePreviewModal = _resolveComponent("TradePreviewModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, {
      class: "relative bal-card-borders-color-changing",
      shadow: _ctx.tradeCardShadow,
      "no-border": !_ctx.darkMode
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_TradeSettingsPopover, {
            context: _ctx.TradeSettingsContext.trade
          }, null, 8, ["context"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_TradePair, {
            tokenInAmount: _ctx.tokenInAmount,
            "onUpdate:tokenInAmount": _cache[0] || (_cache[0] = ($event: any) => (_ctx.tokenInAmount = $event)),
            tokenInAddress: _ctx.tokenInAddress,
            "onUpdate:tokenInAddress": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tokenInAddress = $event)),
            tokenOutAmount: _ctx.tokenOutAmount,
            "onUpdate:tokenOutAmount": _cache[2] || (_cache[2] = ($event: any) => (_ctx.tokenOutAmount = $event)),
            tokenOutAddress: _ctx.tokenOutAddress,
            "onUpdate:tokenOutAddress": _cache[3] || (_cache[3] = ($event: any) => (_ctx.tokenOutAddress = $event)),
            exactIn: _ctx.exactIn,
            "onUpdate:exactIn": _cache[4] || (_cache[4] = ($event: any) => (_ctx.exactIn = $event)),
            priceImpact: _ctx.priceImpact,
            onAmountChange: _ctx.handleAmountChange,
            class: "mb-4"
          }, null, 8, ["tokenInAmount", "tokenInAddress", "tokenOutAmount", "tokenOutAddress", "exactIn", "priceImpact", "onAmountChange"]),
          (_ctx.error && !(_ctx.poolsLoading || _ctx.isLoadingApprovals))
            ? (_openBlock(), _createBlock(_component_BalAlert, {
                key: 0,
                class: "mb-4",
                type: "error",
                size: "sm",
                title: _ctx.error.header,
                description: _ctx.error.body,
                "action-label": _ctx.error.label,
                block: "",
                onActionClick: _ctx.handleErrorButtonClick
              }, null, 8, ["title", "description", "action-label", "onActionClick"]))
            : _createCommentVNode("", true),
          (_ctx.poolsLoading || _ctx.isLoadingApprovals)
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 1,
                loading: true,
                "loading-label": _ctx.$t('loading'),
                block: ""
              }, null, 8, ["loading-label"]))
            : (_openBlock(), _createBlock(_component_BalBtn, {
                key: 2,
                label: 'Preview Trade',
                disabled: _ctx.tradeDisabled,
                "loading-label": _ctx.$t('confirming'),
                color: "gradient",
                block: "",
                onClick: _withModifiers(_ctx.showTradePreviewModal, ["prevent"])
              }, null, 8, ["disabled", "loading-label", "onClick"]))
        ]),
        (_ctx.tradeSuccess)
          ? (_openBlock(), _createBlock(_component_SuccessOverlay, {
              key: 0,
              title: _ctx.$t('tradeSettled'),
              description: _ctx.$t('tradeSuccess'),
              closeLabel: _ctx.$t('close'),
              "explorer-link": _ctx.explorer.txLink(_ctx.txHash),
              onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.tradeSuccess = false))
            }, null, 8, ["title", "description", "closeLabel", "explorer-link"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["shadow", "no-border"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.modalTradePreviewIsOpen)
        ? (_openBlock(), _createBlock(_component_TradePreviewModal, {
            key: 0,
            "is-v1-swap": _ctx.sorReturn.isV1swap,
            "address-in": _ctx.tokenInAddress,
            "amount-in": _ctx.tokenInAmount,
            "address-out": _ctx.tokenOutAddress,
            "amount-out": _ctx.tokenOutAmount,
            trading: _ctx.trading,
            onTrade: _ctx.trade,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalTradePreviewIsOpen = false))
          }, null, 8, ["is-v1-swap", "address-in", "amount-in", "address-out", "amount-out", "trading", "onTrade"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}