import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "chart mr-n2 ml-n2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalLineChart = _resolveComponent("BalLineChart")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBlankSlate = _resolveComponent("BalBlankSlate")!

  return (_ctx.loading || _ctx.appLoading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-96"
      }))
    : (_ctx.nonEmptySnapshots.length >= 7)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BalLineChart, {
            data: _ctx.series,
            isPeriodSelectionEnabled: false,
            axisLabelFormatter: { yAxis: '0.00%' },
            color: _ctx.chartColors,
            height: "150",
            showLegend: true,
            legendState: { HODL: false },
            "hide-y-axis": ""
          }, null, 8, ["data", "axisLabelFormatter", "color"])
        ]))
      : (_openBlock(), _createBlock(_component_BalBlankSlate, {
          key: 2,
          class: "h-96"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, { name: "bar-chart" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('insufficientData')), 1)
          ]),
          _: 1
        }))
}