document.addEventListener("DOMContentLoaded", () => {
  scrambleCategories();
});

document.addEventListener("load", function() {
  scrambleCategories();
});

export function scrambleCategories() {
  const targets = document.querySelectorAll(".glitch-target"), // targets of the glitch effect
    characters = "!<>-_\\/[]{}—=+*^?#________", // characters to be scramble with
    pauseInBetween = 10000; // pause between two words

  class TextScramble {
    constructor(element) {
      this.el = element;
      this.chars = "!<>-_\\/[]{}—=+*^?#________";
      this.update = this.update.bind(this);
    }
    setText(newText) {
      const oldText = this.el.innerText;
      const length = Math.max(oldText.length, newText.length);
      const promise = new Promise((resolve) => (this.resolve = resolve));
      this.queue = [];
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || "";
        const to = newText[i] || "";
        const start = Math.floor(Math.random() * 40);
        const end = start + Math.floor(Math.random() * 40);
        this.queue.push({ from, to, start, end });
      }
      cancelAnimationFrame(this.frameRequest);
      this.frame = 0;
      this.update();
      return promise;
    }
    update() {
      let output = "";
      let complete = 0;
      for (let i = 0, n = this.queue.length; i < n; i++) {
        let { from, to, start, end, char } = this.queue[i];
        if (this.frame >= end) {
          complete++;
          output += to;
        } else if (this.frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = this.randomChar();
            this.queue[i].char = char;
          }
          output += `<span class="dud">${char}</span>`;
        } else {
          output += from;
        }
      }
      this.el.innerHTML = output;
      if (complete === this.queue.length) {
        this.resolve();
      } else {
        this.frameRequest = requestAnimationFrame(this.update);
        this.frame++;
      }
    }
    randomChar() {
      return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
  }

  Array.from(targets).forEach((target) => {
    // push category into empty array
    const category = target.textContent;
    let words = [];
    words.push(category, category, category, category);

    // for each target, build animation
    const scrambling = new TextScramble(target);
    let counter = 0;
    const next = () => {
      scrambling.setText(words[counter]).then(() => {
        setTimeout(next, pauseInBetween);
      });
      counter = (counter + 1) % words.length;
    };
    next();
  }); // end for each
} // end scrambleCategories()
