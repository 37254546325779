import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-gray-200 text-sm" }
const _hoisted_2 = { class: "flex justify-between items-center mb-4" }
const _hoisted_3 = {
  key: 1,
  class: "grid gap-2 grid-cols-2"
}

import { computed, onBeforeMount, toRef } from 'vue';
import useWithdrawMath from '@/components/forms/pool_actions/WithdrawForm/composables/useWithdrawMath';
import { FullPool } from '@/services/balancer/subgraph/types';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';
import { getAddress } from '@ethersproject/address';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import FarmActionsCard from '@/embr/components/pages/farm/FarmActionsCard.vue';
import NormalPoolActionsCard from '../farm/NormalPoolActionsCard.vue';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { initMath, hasBpt } = useWithdrawMath(toRef(props, 'pool'));
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { isWalletReady, toggleWalletSelectModal } = useWeb3();

/**
 * COMPUTED
 */
const fiatTotal = computed(() => {
  const fiatValue = props.pool.tokenAddresses
    .map(address => {
      let tokenBalance = '0';

      if (address === wrappedNativeAsset.value.address) {
        const wrappedBalance = balanceFor(address);
        const nativeBalance = balanceFor(nativeAsset.address);
        tokenBalance = bnum(nativeBalance).gt(wrappedBalance)
          ? nativeBalance
          : wrappedBalance;
      } else {
        tokenBalance = balanceFor(address);
      }

      return toFiat(tokenBalance, address);
    })
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );

  return fNum(fiatValue, currency.value);
});

const hasUnstakedBpt = computed(
  () =>
    props.pool.farm &&
    parseFloat(balanceFor(getAddress(props.pool.address))) > 0
);

const hasFarm = computed(() => !!props.pool.farm || props.pool.address == "0xae0464DE7a71CF733659C93CEE11F35A5Ad24004" || props.pool.address == "0x9042D74bDf78fdB27005C8065F49Cfc20ec4296c");

const hasFarmStake = computed(
  () => props.pool.farm?.stake && props.pool.farm.stake > 0
);

const farmId = computed(() => props.pool.farm?.id || '');
const tokenAddress = computed(() => props.pool.address);
const hasFarmRewards = computed(() => props.pool.farm?.rewards || 0 > 0);

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  initMath();
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BalCard, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('basedOnTokensInWallet')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('youCanInvest')), 1),
          _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fiatTotal) : '-'), 1)
        ]),
        (!_unref(isWalletReady))
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              label: _ctx.$t('connectWallet'),
              color: "gradient",
              block: "",
              onClick: _unref(toggleWalletSelectModal)
            }, null, 8, ["label", "onClick"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BalBtn, {
                tag: "router-link",
                to: { name: 'invest' },
                label: _ctx.$t('invest'),
                color: "gradient",
                block: ""
              }, null, 8, ["label"]),
              _createVNode(_component_BalBtn, {
                tag: _unref(hasBpt) ? 'router-link' : 'div',
                to: { name: 'withdraw' },
                label: _ctx.$t('withdraw.label'),
                disabled: !_unref(hasBpt),
                block: ""
              }, null, 8, ["tag", "label", "disabled"])
            ]))
      ]),
      _: 1
    }),
    (!_unref(hasFarm))
      ? (_openBlock(), _createBlock(NormalPoolActionsCard, {
          key: 0,
          "token-address": _unref(tokenAddress)
        }, null, 8, ["token-address"]))
      : _createCommentVNode("", true),
    (_unref(hasFarm))
      ? (_openBlock(), _createBlock(FarmActionsCard, {
          key: 1,
          "has-unstaked-bpt": _unref(hasUnstakedBpt),
          "token-address": _unref(tokenAddress),
          "farm-id": _unref(farmId),
          "has-farm-rewards": _unref(hasFarmRewards)
        }, null, 8, ["has-unstaked-bpt", "token-address", "farm-id", "has-farm-rewards"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})