
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['create', 'close'],
  props: {},
  setup(props, { emit }) {
    function create() {
      emit('create');
    }

    function onClose() {
      emit('close');
    }

    return {
      onClose,
      create
    };
  }
});
