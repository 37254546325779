import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8c406d16")
const _hoisted_1 = { class: "-mx-4 p-4 flex items-center border-b border-t dark:border-gray-800" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "text-gray-200 text-sm" }
const _hoisted_5 = { class: "mt-6 mb-3 text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "mb-3 card-container"
}
const _hoisted_7 = { class: "card-step text-white" }
const _hoisted_8 = {
  key: 1,
  class: "text-gray-200 dark:text-gray-400"
}
const _hoisted_9 = { class: "ml-3" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "card-container"
}
const _hoisted_13 = { class: "card-step text-white" }
const _hoisted_14 = {
  key: 1,
  class: "text-gray-200 dark:text-gray-400"
}
const _hoisted_15 = { class: "ml-3" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "mt-3 card-container" }
const _hoisted_19 = { class: "card-step text-gray-200 dark:text-gray-400" }
const _hoisted_20 = { class: "ml-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    onClose: _ctx.onClose,
    title: _ctx.$t('previewTradeTransactions')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BalAssetSet, {
            addresses: [_ctx.addressIn, _ctx.addressOut],
            size: 32,
            width: 55
          }, null, 8, ["addresses"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fNum(_ctx.amountIn, 'token')) + " " + _toDisplayString(_ctx.symbolIn) + " -> " + _toDisplayString(_ctx.fNum(_ctx.amountOut, 'token')) + " " + _toDisplayString(_ctx.symbolOut), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fNum(_ctx.valueIn, 'usd')), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, " Requires " + _toDisplayString(_ctx.totalRequiredTransactions) + " " + _toDisplayString(_ctx.requiresApproval ? 'transactions' : 'transaction') + ": ", 1),
          _createElementVNode("div", null, [
            (_ctx.requiresLidoRelayerApproval)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.isLidoRelayerApproved)
                      ? (_openBlock(), _createBlock(_component_BalIcon, {
                          key: 0,
                          name: "check",
                          class: "text-white"
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, "1"))
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.isLidoRelayerApproved)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('approvedLidoRelayer')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('approveLidoRelayer')), 1))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.requiresTokenApproval)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.isTokenApproved)
                      ? (_openBlock(), _createBlock(_component_BalIcon, {
                          key: 0,
                          name: "check",
                          class: "text-white"
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.requiresLidoRelayerApproval ? 2 : 1), 1))
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    (_ctx.isTokenApproved)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('approved')) + " " + _toDisplayString(_ctx.symbolIn), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('approve')) + " " + _toDisplayString(_ctx.symbolIn), 1))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.totalRequiredTransactions), 1),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('trade')) + " " + _toDisplayString(_ctx.fNum(_ctx.valueIn, 'usd')) + " " + _toDisplayString(_ctx.symbolIn) + " -> " + _toDisplayString(_ctx.symbolOut), 1)
            ])
          ])
        ]),
        (_ctx.requiresLidoRelayerApproval && !_ctx.isLidoRelayerApproved)
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              class: "mt-5",
              label: _ctx.$t('approveLidoRelayer'),
              loading: _ctx.approvingLidoRelayer,
              "loading-label": `${_ctx.$t('approvingLidoRelayer')}…`,
              color: "gradient",
              block: "",
              onClick: _withModifiers(_ctx.approveLidoRelayer, ["prevent"])
            }, null, 8, ["label", "loading", "loading-label", "onClick"]))
          : (_ctx.requiresTokenApproval && !_ctx.isTokenApproved)
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 1,
                class: "mt-5",
                label: `${_ctx.$t('approve')} ${_ctx.symbolIn}`,
                loading: _ctx.approvingToken,
                "loading-label": `${_ctx.$t('approving')} ${_ctx.symbolIn}…`,
                color: "gradient",
                block: "",
                onClick: _withModifiers(_ctx.approveToken, ["prevent"])
              }, null, 8, ["label", "loading", "loading-label", "onClick"]))
            : (_openBlock(), _createBlock(_component_BalBtn, {
                key: 2,
                class: "mt-5",
                label: _ctx.$t('confirmTrade'),
                loading: _ctx.trading,
                "loading-label": _ctx.$t('confirming'),
                color: "gradient",
                block: "",
                onClick: _withModifiers(_ctx.trade, ["prevent"])
              }, null, 8, ["label", "loading", "loading-label", "onClick"]))
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}