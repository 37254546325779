
import { computed, defineComponent, PropType, ref } from 'vue';
import useNumbers from '@/composables/useNumbers';
import { sumBy } from 'lodash';
import numeral from 'numeral';
import usePools from '@/composables/pools/usePools';
import useEthers from '@/composables/useEthers';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import useTransactions from '@/composables/useTransactions';
import { Alert } from '@/composables/useAlerts';
//import { useXEmbr } from '@/embr/composables/stake/useXEmbr';
import useProtocolDataQuery from '@/embr/composables/queries/useProtocolDataQuery';
import { erc20ContractService } from '@/embr/services/erc20/erc20-contracts.service';
import { configService } from '@/services/config/config.service';
import { WalletToken } from '@/types';

export default defineComponent({
  name: 'AppNavEmbr',

  data: () => {
    return {
      statusV1: 0
    };
  },

  props: {
    Alert: { type: Object as PropType<Alert>, required: true }
  },

  watch: {
    loading: function() {
      if (!this.loading) {
        this.fetchV1();
      }
    }
  },

  methods: {
    fetchV1: async function() {
      const { account } = this;
      const allowance = await erc20ContractService.erc20.allowance(
        '0x117E0b609C7eEDafeF1A83Ad692dE52817A0B2F6',
        account,
        '0x8A50748a79D20F493F4776C07C922e52eFD61c95'
      );
      const balance = await erc20ContractService.erc20.balanceOf(
        '0x9FBA6AacB11010999355E60675A734278345B13C',
        account
      );

      if (Number(balance) > 0) {
        if (Number(allowance) > 0) {
          this.statusV1 = 2;
        } else {
          this.statusV1 = 1;
        }
      } else {
        this.statusV1 = 0;
      }
    },
    approveV1: async function () {
      const tx = await erc20ContractService.erc20.approveToken(
        this.getProvider(),
        '0x8A50748a79D20F493F4776C07C922e52eFD61c95',
        '0x9FBA6AacB11010999355E60675A734278345B13C',
        '115792089237316195423570985008687907853269984665640564039457584007913129639935'
      );
      this.addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: `Approving V1 for V2`,
        details: {
          contractAddress: '0x9FBA6AacB11010999355E60675A734278345B13C',
          spender: '0x8A50748a79D20F493F4776C07C922e52eFD61c95'
        }
      });
      this.statusV1 = 2
      return tx;
    },
    migrateV1: async function () {
      const tx = await erc20ContractService.erc20.migrateToken(
        this.getProvider(),
        '0x9FBA6AacB11010999355E60675A734278345B13C'
      );
      this.addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: `Migrating V1 for V2`,
        details: {
          contractAddress: '0x8A50748a79D20F493F4776C07C922e52eFD61c95',
          v1: '0x9FBA6AacB11010999355E60675A734278345B13C'
        }
      });
      this.statusV1 = 0
      return tx;
    }
  },

  setup() {
    const { getProvider, account, getAddTokenToWallet } = useWeb3();
    const { addTransaction } = useTransactions();

    const { fNum } = useNumbers();
    const { upToLargeBreakpoint } = useBreakpoints();

    const protocolDataQuery = useProtocolDataQuery();
    const tvl = computed(
      () => protocolDataQuery.data?.value?.totalLiquidity || 0
    );

    const embrPrice = computed(
      () => protocolDataQuery.data?.value?.embrPrice || 0
    );
    const circulatingSupply = computed(
      () => protocolDataQuery.data.value?.circulatingSupply || 0
    );
    const marketCap = computed(() => {
      return embrPrice.value * circulatingSupply.value;
    });
    const loading = computed(() => protocolDataQuery.isLoading.value);

     async function addTokenToMetaMask() { 
        const token = { 
            address: configService.network.addresses.embr,
            type: 'ERC20',
            symbol: "WAVE",
            decimals: 18,
            logoURI: "https://raw.githubusercontent.com/wavelength-velas/assets/main/assets/wavelength_logo.png"  
      } as WalletToken;
      await getAddTokenToWallet(token)
    }

    return {
      account,
      fNum,
      upToLargeBreakpoint,
      embrPrice,
      tvl,
      circulatingSupply,
      marketCap,
      loading,
      addTransaction,
      getProvider,
      addTokenToMetaMask
    };
  }
});
