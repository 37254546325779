<template>
  <v-card class="mb-7">
    <v-toolbar flat>
      <v-toolbar-title><h3 class="text-white dark:text-white text-xl tracking-wider">1 {{baseCurrency.toUpperCase()}} = {{currentPrice}} {{vsCurrency.toUpperCase()}}</h3></v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="mt-4 pa-4">
      <vue-apex-charts
        type="candlestick"
        height="350"
        :options="apexChartData.candlestickChart.chartOptions"
        :series="waveChartData"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import apexChartData, { dohlcData } from './ApexChartsData.js';

export default {
  name: 'WaveCandleStickChart',
  props: {
    baseCurrency: {
      type: String,
      default: "bitcoin",
    },
    vsCurrency: {
      type: String,
      default: "ethereum",
    },
    otherCurrency: {
      type: String,
      default: "usd",
    },
    days: {
      type: Number,
      default: 7,
    },
  },
  data: () => ({
    candlechart: false,
    apexChartData: apexChartData,
    waveChartData: [],
    currentPrice: 0
  }),
  async created(baseCurrency, vsCurrency, otherCurrency, days) {
    let waveChartData = await dohlcData(baseCurrency, vsCurrency, otherCurrency, days);
    let chartValues = waveChartData.series;
    this.waveChartData = chartValues
    this.currentPrice = chartValues[0].data[chartValues[0].data.length-1].y[1]
    
  },
  components: {
    VueApexCharts
  },
  // update vue component on days prop change
  watch: {
    baseCurrency: async function(val, oldVal) {
      const newValues = await dohlcData(this.baseCurrency, this.vsCurrency, this.otherCurrency, val)
      let chartValues = newValues.series;
      this.waveChartData = chartValues
      this.currentPrice = chartValues[0].data[chartValues[0].data.length-1].y[1]
    },
    vsCurrency: async function(val, oldVal) {
      const newValues = await dohlcData(this.baseCurrency, this.vsCurrency, this.otherCurrency, val)
      let chartValues = newValues.series;
      this.waveChartData = chartValues
      this.currentPrice = chartValues[0].data[chartValues[0].data.length-1].y[1]
    },
    days: async function(val, oldVal) {
      const newValues = await dohlcData(this.baseCurrency, this.vsCurrency, this.otherCurrency, val)
      let chartValues = newValues.series;
      this.waveChartData = chartValues
      this.currentPrice = chartValues[0].data[chartValues[0].data.length-1].y[1]
    }
  },
  async mounted() {
    // setInterval(async () => {
      const newValues = await dohlcData(this.baseCurrency, this.vsCurrency, this.otherCurrency, this.days)
      let chartValues = newValues.series;
      this.waveChartData = chartValues
      this.currentPrice = chartValues[0].data[chartValues[0].data.length-1].y[1]
    // }, 100)
  }
};
</script>
