import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TradeRouteV2 = _resolveComponent("TradeRouteV2")!

  return (_openBlock(), _createBlock(_component_TradeRouteV2, {
    class: "mt-5",
    "address-in": _ctx.tokenInAddress,
    "amount-in": _ctx.tokenInAmount,
    "address-out": _ctx.tokenOutAddress,
    "amount-out": _ctx.tokenOutAmount,
    pools: _ctx.pools,
    "sor-return": _ctx.sorReturn
  }, null, 8, ["address-in", "amount-in", "address-out", "amount-out", "pools", "sor-return"]))
}