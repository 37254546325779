
import { computed, defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { EXTERNAL_LINKS } from '@/constants/links';
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import usePools from '@/composables/pools/usePools';
import useWeb3 from '@/services/web3/useWeb3';
import PortfolioAssetsPieChart from '@/embr/components/pages/portfolio/PortfolioAssetsPieChart.vue';
import PortfolioPoolsPieChart from '@/embr/components/pages/portfolio/PortfolioPoolsPieChart.vue';
import PortfolioValueLineChart from '@/embr/components/pages/portfolio/PortfolioValueLineChart.vue';
import PortfolioStatWithBarChart from '@/embr/components/pages/portfolio/PortfolioStatWithBarChart.vue';
import { chartColors } from '@/embr/constants/colors';
import PortfolioHeader from '@/embr/components/pages/portfolio/PortfolioHeader.vue';
import { orderBy, sum } from 'lodash';
import useNumbers from '@/composables/useNumbers';
import PortfolioPoolsStatCards from '@/embr/components/pages/portfolio/PortfolioPoolsStatCards.vue';
import usePortfolio from '@/embr/composables/usePortfolio';

export default defineComponent({
  components: {
    PortfolioPoolsStatCards,
    PortfolioHeader,
    PortfolioPoolsPieChart,
    PortfolioStatWithBarChart,
    PortfolioValueLineChart,
    PortfolioAssetsPieChart,
    PoolsTable
  },

  setup() {
    // COMPOSABLES
    const router = useRouter();
    const { isWalletReady, account } = useWeb3();

    const {
      onlyPoolsWithFarms,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      isLoadingFarms,
      loadMorePools,
      poolsHasNextPage,
      poolsIsFetchingNextPage
    } = usePools();
    const { portfolio, portfolioHistory, isLoadingPortfolio } = usePortfolio();

    const { fNum } = useNumbers();

     const poolsWithUserInFarm = computed(() => {
      return onlyPoolsWithFarms.value.filter(
        pool => pool.decoratedFarm.stake > 0
      );
    });

    const timestamps = computed(() =>
      orderBy(portfolioHistory.value, 'timestamp', 'asc').map(
        item => item.timestamp
      )
    );

    const volume = computed(() =>
      orderBy(portfolioHistory.value, 'timestamp', 'asc').map(
        item => item.totalSwapVolume
      )
    );
    const fees = computed(() =>
      orderBy(portfolioHistory.value, 'timestamp', 'asc').map(
        item => item.myFees
      )
    );
    const avgFees = computed(() => sum(fees.value) / fees.value.length);

    watch(account, () => {
      if (!account.value) {
        router.push({ name: 'home' });
      }
    });

    return {
      // data
      poolsWithUserInFarm,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      isLoadingFarms,

      // computed
      isWalletReady,
      poolsHasNextPage,
      poolsIsFetchingNextPage,

      portfolio,
      portfolioHistory,
      isLoadingPortfolio,

      //methods
      router,
      loadMorePools,
      chartColors,
      timestamps,
      fees,
      volume,
      fNum,
      avgFees,

      // constants
      EXTERNAL_LINKS
    };
  }
});
