import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chart mr-n2 ml-n2" }

import LbpLineChart from '@/embr/lbp/components/LbpLineChart.vue';
import { computed } from 'vue';
import useTailwind from '@/composables/useTailwind';
import useLgeCreateState from '@/embr/lbp/composables/useLgeCreateState';
import {
  getLbpPreviewChartPredictedPriceData,
  getLbpNumDays
} from '@/embr/lbp/utils/lbpChartUtils';
import useTokens from '@/composables/useTokens';


export default _defineComponent({
  setup(__props) {

const { data, lgeChartConfigValid } = useLgeCreateState();
const tailwind = useTailwind();
const { priceFor } = useTokens();

const xAxisFormat = computed(() => {
  const numDays = getLbpNumDays(data.value);

  return numDays < 4 ? 'd MMM HH:mm' : 'd MMM';
});

const series = computed(() => {
  const price = priceFor(data.value.collateralTokenAddress);

  return [
    {
      name: 'Predicted Price',
      values:
        lgeChartConfigValid.value === true
          ? getLbpPreviewChartPredictedPriceData(data.value, price)
          : []
    }
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LbpLineChart, {
      data: _unref(series),
      isPeriodSelectionEnabled: false,
      axisLabelFormatter: { yAxis: '$0.000', xAxis: 'datetime' },
      color: [_unref(tailwind).theme.colors.green['400']],
      legendState: {},
      height: "96",
      showLegend: true,
      "disable-animation": true,
      "x-axis-format": _unref(xAxisFormat)
    }, null, 8, ["data", "axisLabelFormatter", "color", "x-axis-format"])
  ]))
}
}

})