
import { computed, defineComponent, PropType, ref } from 'vue';
import numeral from 'numeral';
import * as echarts from 'echarts/core';
import ECharts from 'vue-echarts';
import useNumbers from '@/composables/useNumbers';
import useTailwind from '@/composables/useTailwind';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import { chartColors2 } from '@/embr/constants/colors';
import { UserPoolData } from '@/embr/services/embr/embr-types';
import { sumBy } from 'lodash';

export default defineComponent({
  props: {
    pools: {
      type: Array as PropType<UserPoolData[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BalCard,
    ECharts
  },
  setup(props) {
    const chartInstance = ref<echarts.ECharts>();
    const currentValue = ref('$0,00');
    const change = ref(0);
    const { fNum } = useNumbers();
    const tailwind = useTailwind();
    const showAll = ref(false);

    const chartConfig = computed(() => {
      const majorPools = props.pools.filter(
        pool => pool.percentOfPortfolio >= 0.03
      );
      const others = props.pools.filter(pool => pool.percentOfPortfolio < 0.03);
      const othersValue = sumBy(others, 'totalValue');

      return {
        tooltip: {
          trigger: 'item',
          backgroundColor: tailwind.theme.colors.gray['800'],
          borderColor: tailwind.theme.colors.gray['800'],
          formatter: param => {
            return `
          <div class='flex flex-col font-body bg-white dark:bg-gray-800 dark:text-white'>
            <span>${param.marker} ${
              param.name
            }<span class='font-medium ml-2'>${fNum(param.value, 'usd')}
                  </span>
                </span>
          </div>
          `;
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: 'My Assets',
            type: 'pie',
            radius: ['45%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: tailwind.theme.colors.gray['850'],
              borderWidth: 2
            },
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            data: [
              ...majorPools.map(pool => ({
                name: pool.name,
                value: Math.round(pool.totalValue * 100) / 100
              })),
              ...(othersValue > 0
                ? [
                    {
                      name: 'Other',
                      value: othersValue
                    }
                  ]
                : [])
            ],
            color: chartColors2
          }
        ]
      };
    });

    function toggleShowAll() {
      showAll.value = !showAll.value;
    }

    return {
      //refs
      chartInstance,

      numeral,

      // data
      currentValue,
      change,

      // computed
      chartConfig,
      chartColors2,
      showAll,
      toggleShowAll,
      fNum
    };
  }
});
