import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex flex-col w-48" }
const _hoisted_4 = { class: "text-gray-200 font-medium mb-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "text-gray-200 font-medium" }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "dark:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_ECharts = _resolveComponent("ECharts")!
  const _component_BalBlankSlate = _resolveComponent("BalBlankSlate")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-44"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BalCard, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                  _createVNode(_component_BalTooltip, null, {
                    activator: _withCtx(() => [
                      _createVNode(_component_BalIcon, {
                        name: "info",
                        size: "sm",
                        class: "ml-1 text-gray-400 -mb-px"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.infoText,
                        class: "w-52"
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.stat), 1)
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.subTitle), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.dates.length > 2)
                  ? (_openBlock(), _createBlock(_component_ECharts, {
                      key: 0,
                      ref: "chartInstance",
                      class: _normalizeClass(['w-full', 'h-36']),
                      option: _ctx.chartConfig,
                      autoresize: "",
                      "update-options": { replaceMerge: 'series' }
                    }, null, 8, ["option"]))
                  : (_openBlock(), _createBlock(_component_BalBlankSlate, {
                      key: 1,
                      class: "h-36"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BalIcon, { name: "bar-chart" }),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('insufficientData')), 1)
                      ]),
                      _: 1
                    }))
              ])
            ])
          ]),
          _: 1
        })
      ]))
}