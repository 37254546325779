
import { computed, defineComponent, onMounted, PropType } from 'vue';
import numeral from 'numeral';
import { UserPortfolioData } from '@/embr/services/embr/embr-types';
import useNumbers from '@/composables/useNumbers';
import useProtocolDataQuery from '@/embr/composables/queries/useProtocolDataQuery';
import { sumBy } from 'lodash';
import { DecoratedPoolWithRequiredFarm } from '@/embr/services/subgraph/subgraph-types';
import { scrambleCategories } from '@/embr/utils/AnimationTitle.js'

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<UserPortfolioData>,
      required: true
    },
    pools: {
      type: Array as PropType<DecoratedPoolWithRequiredFarm[]>,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  components: {
    //BalCard
  },
  setup(props) {
    const { fNum } = useNumbers();
    const protocolDataQuery = useProtocolDataQuery();

    const tvl = computed(
      () => protocolDataQuery.data?.value?.totalLiquidity || 0
    );

    const embrPrice = computed(
      () => protocolDataQuery.data?.value?.embrPrice || 0
    );

    const farmData = computed(() => {
      const farms = props.pools.map(pool => pool.decoratedFarm);

      const averageApr =
        sumBy(farms, farm => farm.apr * (farm.stake || 0)) /
        sumBy(farms, farm => farm.stake || 0);
      const dailyApr = averageApr / 365;
      const totalBalance = sumBy(farms, farm => farm.stake || 0);

      return {
        numFarms: farms.filter(farm => farm.stake > 0).length,
        totalBalance: fNum(totalBalance, 'usd'),
        pendingWAVE: numeral(sumBy(farms, farm => farm.pendingWAVE)).format(
          '0,0.[0000]'
        ),
        pendingWAVEValue: fNum(
          sumBy(farms, farm => farm.pendingWAVEValue),
          'usd'
        ),
        apr: fNum(averageApr, 'percent'),
        dailyApr: fNum(dailyApr, 'percent'),
        dailyEmbr: embrPrice.value
          ? fNum((dailyApr * totalBalance) / embrPrice.value)
          : 0
      };
    });

    onMounted(() => {
      scrambleCategories();
    });

    const hasFarmRewards = computed(
      () => props.pools.filter(pool => pool.decoratedFarm.stake > 0).length > 0
    );
    return {
      //refs

      numeral,
      fNum,
      tvl,
      farmData,
      hasFarmRewards
    };
  }
});
