import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "text-xs text-gray-700 dark:text-gray-500" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "font-bold dark:text-gray-300" }
const _hoisted_4 = { class: "bg-gray-50 dark:bg-gray-700 p-2" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "text-sm text-gray-500" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "text-sm text-gray-500" }
const _hoisted_10 = { class: "bg-gray-50 dark:bg-gray-700 p-2" }
const _hoisted_11 = { class: "text-sm" }
const _hoisted_12 = { class: "text-sm" }
const _hoisted_13 = { class: "text-sm" }
const _hoisted_14 = { class: "text-sm" }
const _hoisted_15 = { class: "text-sm capitalize" }
const _hoisted_16 = { class: "text-sm" }
const _hoisted_17 = { class: "text-sm" }
const _hoisted_18 = { class: "text-sm" }
const _hoisted_19 = { class: "text-sm" }

import { ref, computed, onBeforeMount } from 'vue';
import CreateActions from '@/components/cards/CreatePool/CreateActions.vue';

import usePoolCreation from '@/composables/pools/usePoolCreation';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import { useI18n } from 'vue-i18n';
import useWeb3 from '@/services/web3/useWeb3';
import { shortenLabel } from '@/lib/utils';

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  emits: ["close", "success"] as unknown as undefined,
  setup(__props, { emit }: { emit: ({
  (e: 'close'): void;
  (e: 'success'): void;
}), expose: any, slots: any, attrs: any }) {



/**
 * STATE
 */

const poolCreated = ref(false);

/**
 * COMPOSABLES
 */
const {
  seedTokens,
  poolLiquidity,
  getScaledAmounts,
  poolTypeString,
  initialFee,
  goBack,
  name: poolName,
  symbol: poolSymbol,
  setActiveStep,
  useNativeAsset,
  sortSeedTokens,
  feeManagementType,
  feeController,
  thirdPartyFeeController,
  saveState
} = usePoolCreation();

const { tokens, priceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum } = useNumbers();
const { t } = useI18n();
const { userNetworkConfig, account } = useWeb3();

/**
 * LIFECYCLE
 */
onBeforeMount(() => {
  sortSeedTokens();
  saveState();
});

/**
 * COMPUTED
 */
const title = computed((): string =>
  poolCreated.value
    ? t('poolCreated')
    : t('previewPool', [poolTypeString.value])
);

const initialWeightLabel = computed(() => t('initialWeight'));

const tokenAddresses = computed((): string[] => {
  return seedTokens.value.map(token => {
    if (
      token.tokenAddress == wrappedNativeAsset.value.address &&
      useNativeAsset.value
    ) {
      return nativeAsset.address;
    }
    return token.tokenAddress;
  });
});

const tokenAmounts = computed((): string[] => {
  return getScaledAmounts();
});

/**
 * METHODS
 */
function handleSuccess(): void {
  poolCreated.value = true;
  emit('success');
}

function getInitialWeight(tokenAddress: string, tokenAmount: number) {
  return fNum(
    (tokenAmount * priceFor(tokenAddress)) / poolLiquidity.value,
    'percent'
  );
}

function navigateToPoolFee() {
  setActiveStep(1);
}

function getSwapFeeManager() {
  if (feeManagementType.value === 'governance') {
    return t('balancerGovernance');
  } else {
    if (feeController.value === 'self') {
      return `${t('myWallet')}: ${shortenLabel(account.value)}`;
    } else {
      return shortenLabel(thirdPartyFeeController.value);
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_BalInlineInput = _resolveComponent("BalInlineInput")!

  return (_openBlock(), _createBlock(_component_BalStack, {
    vertical: "",
    spacing: "xs",
    class: "mb-24"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalCard, {
        shadow: "xl",
        noBorder: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BalStack, {
            vertical: "",
            spacing: "xs"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(userNetworkConfig)?.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BalStack, { vertical: "" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (poolCreated.value)
                  ? (_openBlock(), _createBlock(_component_BalCircle, {
                      key: 0,
                      size: "8",
                      color: "green",
                      class: "text-white mr-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BalIcon, { name: "check" })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_BalStack, {
                  horizontal: "",
                  align: "center",
                  spacing: "xs"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(goBack) && _unref(goBack)(...args))),
                      class: "text-blue-500 hover:text-blue-700 flex"
                    }, [
                      _createVNode(_component_BalIcon, {
                        class: "flex",
                        name: "chevron-left"
                      })
                    ]),
                    _createElementVNode("h5", _hoisted_3, _toDisplayString(_unref(title)), 1)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_BalCard, {
                shadow: "none",
                noPad: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.$t('createAPool.tokensAndSeedLiquidity')), 1)
                  ]),
                  _createVNode(_component_BalStack, {
                    vertical: "",
                    spacing: "none",
                    withBorder: "",
                    isDynamic: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(seedTokens), (token) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `tokenpreview-${token.tokenAddress}`,
                          class: "p-4"
                        }, [
                          _createVNode(_component_BalStack, {
                            horizontal: "",
                            justify: "between"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BalStack, {
                                horizontal: "",
                                align: "center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BalAsset, {
                                    address: token.tokenAddress,
                                    size: 36
                                  }, null, 8, ["address"]),
                                  _createVNode(_component_BalStack, {
                                    vertical: "",
                                    spacing: "none"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(fNum)(token.weight / 100, 'percent')) + " " + _toDisplayString(_unref(tokens)[token.tokenAddress].symbol), 1),
                                      _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(initialWeightLabel)) + ": " + _toDisplayString(getInitialWeight(token.tokenAddress, token.amount)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_BalStack, {
                                vertical: "",
                                spacing: "none",
                                align: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(fNum)(token.amount, 'token')), 1),
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(fNum)(token.amount * _unref(priceFor)(token.tokenAddress), 'usd')), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BalStack, {
                    horizontal: "",
                    justify: "between",
                    class: "p-4 border-t dark:border-gray-600"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('total')), 1),
                      _createElementVNode("h6", null, _toDisplayString(_unref(fNum)(_unref(poolLiquidity), 'usd')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_BalCard, {
                shadow: "none",
                noPad: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.$t('summary')), 1)
                  ]),
                  _createVNode(_component_BalStack, {
                    vertical: "",
                    spacing: "xs",
                    class: "p-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BalStack, {
                        horizontal: "",
                        justify: "between"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('poolSymbol')) + ":", 1),
                          _createVNode(_component_BalInlineInput, {
                            size: "xs",
                            modelValue: _unref(poolName),
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(poolName) ? poolName.value = $event : null)),
                            inputAlignRight: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BalStack, {
                        horizontal: "",
                        justify: "between"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('poolName')) + ":", 1),
                          _createVNode(_component_BalInlineInput, {
                            size: "xs",
                            modelValue: _unref(poolSymbol),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(poolSymbol) ? poolSymbol.value = $event : null)),
                            inputAlignRight: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BalStack, {
                        horizontal: "",
                        justify: "between"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('poolType')) + ":", 1),
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(poolTypeString)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BalStack, {
                        horizontal: "",
                        justify: "between"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('swapFee')) + ":", 1),
                          _createVNode(_component_BalStack, {
                            horizontal: "",
                            spacing: "sm"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(fNum)(_unref(initialFee), 'percent')), 1),
                              _createElementVNode("button", {
                                class: "hover:text-blue-500",
                                onClick: navigateToPoolFee
                              }, [
                                _createVNode(_component_BalIcon, {
                                  name: "edit",
                                  size: "xs"
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BalStack, {
                        horizontal: "",
                        justify: "between"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('swapFeeManager')) + ":", 1),
                          _createVNode(_component_BalStack, {
                            horizontal: "",
                            spacing: "sm"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_19, _toDisplayString(getSwapFeeManager()), 1),
                              _createElementVNode("button", {
                                class: "hover:text-blue-500",
                                onClick: navigateToPoolFee
                              }, [
                                _createVNode(_component_BalIcon, {
                                  name: "edit",
                                  size: "xs"
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(CreateActions, {
                tokenAddresses: _unref(tokenAddresses),
                amounts: _unref(tokenAmounts),
                onSuccess: handleSuccess
              }, null, 8, ["tokenAddresses", "amounts"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})