
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { EXTERNAL_LINKS } from '@/constants/links';
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import usePools from '@/composables/pools/usePools';
import useWeb3 from '@/services/web3/useWeb3';
import usePoolFilters from '@/composables/pools/usePoolFilters';
import useAlerts, { AlertPriority, AlertType } from '@/composables/useAlerts';
import BalTabs from '@/components/_global/BalTabs/BalTabs.vue';
import InvestFeaturedPoolsCard from '@/embr/components/pages/invest/InvestFeaturedPoolsCard.vue';
import useEmbrConfig from '@/embr/composables/useEmbrConfig';
import TokenSearchInput from '@/components/inputs/TokenSearchInput.vue';
import { flatten } from 'lodash';
import { scrambleCategories } from '@/embr/utils/AnimationTitle.js'
import ComposePoolTooltip from '@/components/tooltips/ComposePoolTooltip.vue';
import BalAlertUnstaked from '@/components/_global/BalAlert/BalAlertUnstaked.vue';

export default defineComponent({
  components: {
    InvestFeaturedPoolsCard,
    TokenSearchInput,
    PoolsTable,
    BalTabs,
    ComposePoolTooltip,
    BalAlertUnstaked
},

  setup() {
    // COMPOSABLES
    const router = useRouter();
    const { t } = useI18n();
    const { isWalletReady, isV1Supported, appNetworkConfig } = useWeb3();
    const isElementSupported = appNetworkConfig.supportsElementPools;
    const {
      selectedTokens,
      addSelectedToken,
      removeSelectedToken
    } = usePoolFilters();

    onMounted(() => {
  scrambleCategories();
});

    const {
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      loadMorePools,
      poolsHasNextPage,
      poolsIsFetchingNextPage,
      poolsQuery,
      isLoadingFarms,
      communityPools,
      embrPools,
      poolsWithFarms
    } = usePools(selectedTokens);
    const { addAlert, removeAlert } = useAlerts();

  const { embrConfig, embrConfigLoading } = useEmbrConfig();

    const tabs = [
      { value: 'wavelength-pools', label: 'Incentivized Pools' },
      { value: 'community-pools', label: 'Community Pools' },
      { value: 'my-investments', label: 'My Stakes' }
    ];

    const activeTab = ref(tabs[0].value);
    const activeFilters = ref<string[]>([]);

    const hideV1Links = computed(() => !isV1Supported);

    const hasUnstakedBpt = computed(() =>
      userPools.value.find(pool => pool.farm && parseFloat(pool.shares) > 0)
    );

      const featuredPools = computed(() => {
      const filtered = (poolsWithFarms.value || []).filter(pool =>
        embrConfig.value.featuredPools.includes(pool.id)
      );

      return filtered.slice(0, 4);
    });

const filteredEmbrPools = computed(() => {
      if (activeFilters.value.length === 0 || !embrPools.value) {
        return embrPools.value;
      }

      const selected = embrConfig.value.poolFilters.filter(filter =>
        activeFilters.value.includes(filter.id)
      );
      const poolIds = flatten(selected.map(selected => selected.pools));

      return embrPools.value?.filter(pool => poolIds.includes(pool.id));
    });


    function goToPoolCreate() {
      router.push({ name: 'pool-create' }); //router.push({ name: 'create-pool' });
    }

    function toggleFilter(filterId: string) {
      if (activeFilters.value.includes(filterId)) {
        activeFilters.value = activeFilters.value.filter(id => id !== filterId);
      } else {
        activeFilters.value.push(filterId);
      }
    }

    watch(poolsQuery.error, () => {
      if (poolsQuery.error.value) {
        addAlert({
          id: 'pools-fetch-error',
          label: t('alerts.pools-fetch-error'),
          type: AlertType.ERROR,
          persistent: true,
          action: poolsQuery.refetch.value,
          actionLabel: t('alerts.retry-label'),
          priority: AlertPriority.MEDIUM
        });
      } else {
        removeAlert('pools-fetch-error');
      }
    });

    return {
      // data
      embrPools,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      embrConfigLoading,

      // computed
      isWalletReady,
      hideV1Links,
      poolsHasNextPage,
      poolsIsFetchingNextPage,
      selectedTokens,
      isElementSupported,

      //methods
      router,
      loadMorePools,
      addSelectedToken,
      removeSelectedToken,
      goToPoolCreate,
      hasUnstakedBpt,
      communityPools,
      isLoadingFarms,
      tabs,
      activeTab,
      featuredPools,
      embrConfig,
      toggleFilter,
      activeFilters,
      filteredEmbrPools,

      // constants
      EXTERNAL_LINKS
    };
  }
});
