import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

import { ref, nextTick, onMounted } from 'vue';
import anime from 'animejs';
import { takeRight } from 'lodash';

type Section = {
  title: string;
  id: string;
};

type Props = {
  sections: Section[];
};


export default _defineComponent({
  props: {
    sections: { type: Array, required: true }
  } as unknown as undefined,
  setup(__props: {
  sections: Section[];
}) {

const props = __props


const activeSection = ref('');
const activeSectionElement = ref<HTMLElement>();
const accordionHeightSetterElement = ref<HTMLElement>();
const wrapperElement = ref<HTMLElement>();
const handleBarElement = ref<HTMLElement>();
const arrowElement = ref<HTMLElement>();
const handleBarElements = ref<HTMLElement[]>([]);

const minimisedWrapperHeight = ref(0);
const isContentVisible = ref(false);
const height = ref();
const handleBarHeight = ref(0);
const totalHeight = ref(0);

const easing = 'spring(0.2, 150, 18, 0)';

async function toggleSection(section: string) {
  const collapseCurrentSection = activeSection.value === section;
  if (collapseCurrentSection) {
    activeSection.value = '';
    isContentVisible.value = false;
  } else {
    activeSection.value = section;
    isContentVisible.value = true;
  }
  await nextTick();
  if (activeSectionElement.value && accordionHeightSetterElement.value) {
    height.value = activeSectionElement.value.clientHeight;
    isContentVisible.value = false;
  }

  handleBarElements.value.forEach(handleBar => {
    anime({
      targets: handleBar,
      translateY: `0px`,
      easing
    });
  });

  const activeSectionIndex = props.sections.findIndex(
    s => s.id === activeSection.value
  );
  const handleBarsToTransform = takeRight(
    handleBarElements.value,
    handleBarElements.value.length - (activeSectionIndex + 1)
  );

  // unfortunately this does introduce reflow (animating height of total)
  // but it way better than having to animate the height of 2 sections
  // the one minimising + the one maximising
  const heightToAnimate = collapseCurrentSection
    ? minimisedWrapperHeight.value
    : minimisedWrapperHeight.value + height.value;
  anime({
    targets: wrapperElement.value,
    height: `${heightToAnimate}px`,
    easing
  });
  handleBarsToTransform.forEach(handleBar => {
    const y = collapseCurrentSection ? 0 : height.value;
    anime({
      targets: handleBar,
      translateY: `${y}px`,
      easing
    });
  });

  // animate the arrow
  anime({
    targets: arrowElement.value,
    rotate: '90deg'
  });

  setTimeout(async () => {
    isContentVisible.value = true;
    await nextTick();
    if (activeSectionElement.value) {
      anime.set(activeSectionElement.value, {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        opacity: 0
      });
      anime({
        targets: activeSectionElement.value,
        opacity: 1
      });
    }
  }, 300);
}

// all of this happens without the user seeing any feedback
onMounted(async () => {
  // set to true so we can actually measure the content height
  isContentVisible.value = true;

  // set the height of the minimised accordion
  minimisedWrapperHeight.value = wrapperElement.value?.offsetHeight || 0;

  handleBarHeight.value = handleBarElement.value?.offsetHeight || 0;

  // the total expanded height starts with tracking the minimised height first
  totalHeight.value = wrapperElement.value?.offsetHeight || 0;

  // calculating the height of the completely expanded accordion
  // by summing the heights of each section onto the minimised
  // height of the accordion
  for (const section of props.sections) {
    activeSection.value = section.id;
    await nextTick();
    totalHeight.value =
      totalHeight.value + (activeSectionElement.value?.offsetHeight || 0);
  }

  // need to set this back to false so its like the accordion
  // was never active
  activeSection.value = '';
  isContentVisible.value = false;
});

function setHandleBars(el: HTMLElement) {
  if (!handleBarElements.value?.includes(el)) {
    handleBarElements.value.push(el);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", { ref: wrapperElement }, [
    _createVNode(_component_BalCard, {
      hFull: "",
      "no-pad": ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.sections, (section, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex flex-col",
            key: section.id,
            ref: setHandleBars
          }, [
            _createElementVNode("button", {
              ref: handleBarElement,
              onClick: ($event: any) => (toggleSection(section.id)),
              class: _normalizeClass([
            'w-full flex justify-between p-3 hover:bg-gray-50 dark:hover:bg-gray-800',
            {
              'border-b dark:border-gray-900': i !== __props.sections.length - 1
            }
          ])
            }, [
              _createElementVNode("h6", null, _toDisplayString(section.title), 1),
              _createVNode(_component_BalIcon, {
                class: "text-white",
                name: "chevron-down"
              })
            ], 10, _hoisted_1),
            (activeSection.value === section.id)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "relative",
                  ref: accordionHeightSetterElement
                }, [
                  (isContentVisible.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        ref: activeSectionElement,
                        class: _normalizeClass({ 'border-b': isContentVisible.value })
                      }, [
                        _renderSlot(_ctx.$slots, section.id)
                      ], 2))
                    : _createCommentVNode("", true)
                ], 512))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 3
    })
  ], 512))
}
}

})