
import { computed, defineComponent, PropType } from 'vue';
import BalIcon from '../BalIcon/BalIcon.vue';
import BalBtn from '../BalBtn/BalBtn.vue';

type AlertType = 'warning' | 'error' | 'info';

export default defineComponent({
  name: 'BalAlert',

  components: {
    BalIcon,
    BalBtn
  },

  emits: ['actionClick'],

  props: {
    type: { type: String as PropType<AlertType>, default: 'info' },
    size: {
      type: String,
      default: 'md',
      validator: (val: string): boolean => ['sm', 'md', 'lg'].includes(val)
    },
    title: { type: String, default: 'A title message' },
    description: { type: String },
    actionLabel: { type: String },
    raised: { type: Boolean, default: false },
    block: { type: Boolean, default: false }
  },

  setup(props, { slots }) {
    const bgColorClass = computed(() => {
      switch (props.type) {
        case 'warning':
          return 'bg-gold-50 dark:bg-gold-500 border border-gold-200 dark:border-0 text-black dark:text-black';
        case 'error':
          return 'bg-red-50 dark:bg-red-600 border border-red-200 dark:border-0 text-black dark:text-white';
        default:
          return 'bg-gray-100 dark:bg-gray-500 border dark:border-0 border-gray-200 text-black dark:text-white';
      }
    });

    const paddingClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'p-1';
        default:
          return 'p-2';
      }
    });

    const textSizeClass = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'text-xs';
        case 'lg':
          return 'text-base';
        default:
          return 'text-sm';
      }
    });

    const classes = computed(() => {
      return {
        [bgColorClass.value]: true,
        [paddingClasses.value]: true,
        'shadow-sm': props.raised,
        'w-full': props.block
      };
    });

    const containerClasses = computed(() => ({
      'items-center': !props.description && !slots.default
    }));

    const contentClasses = computed(() => ({
      'items-center': !props.description && !slots.default,
      'flex-col': !!props.description || slots.default
    }));

    const iconSizeClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'w-5 h-5';
        case 'lg':
          return 'w-7 h-7';
        default:
          return 'w-6 h-6';
      }
    });

    const iconColorClasses = computed(() => {
      switch (props.type) {
        case 'warning':
          return 'text-purple-500 dark:text-white bg-purple-500 dark:bg-white bg-opacity-10 dark:bg-opacity-10';
        case 'error':
          return 'text-red-500 dark:text-white bg-red-600 dark:bg-white bg-opacity-10 dark:bg-opacity-10';
        default:
          return 'text-gray-200 dark:text-white bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10';
      }
    });

    const iconClasses = computed(() => {
      return {
        [iconSizeClasses.value]: true,
        [iconColorClasses.value]: true
      };
    });

    const titleClasses = computed(() => ({
      'font-bold': !!props.description || slots.default
    }));

    const descriptionColor = computed(() => {
      if (props.type === 'info')
        return 'text-black dark:text-white text-opacity-70';
      return 'text-black dark:text-white text-opacity-70';
    });

    const iconSize = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'sm';
        default:
          return 'md';
      }
    });

    const btnColor = computed(() => {
      switch (props.type) {
        case 'warning':
          return 'purple';
        case 'error':
          return 'red';
        default:
          return 'gray';
      }
    });

    const actionClasses = computed(() => ({
      'pl-4': !props.description && !slots.default,
      'mt-1': !!props.description || slots.default
    }));

    return {
      classes,
      containerClasses,
      contentClasses,
      iconClasses,
      iconSize,
      titleClasses,
      textSizeClass,
      descriptionColor,
      btnColor,
      actionClasses
    };
  }
});
