
import { SupportedWallets } from '@/services/web3/web3.plugin';
import WalletButton from '@/components/web3/WalletButton.vue';
import { EXTERNAL_LINKS } from '@/constants/links';
import { ref, defineComponent } from 'vue';
import { findLastKey } from 'lodash';

export default defineComponent({
  emits: ['close'],
  components: {
    WalletButton
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() { 
    return {
      counter: false,
      name: "I Accept"
    }
  },
  methods: {
  modify(){
    this.counter = true;
    this.name = "Accepted";
  },
  greet() {
    if (this.counter) {
      return "block"
    }else{
      return "hidden"
    }
  }
},
  setup() {    
    function greet2(grade) {
    if (grade) {
      return "block"
    }else{
      return "hidden"
    }
  }
    return {
      wallets: SupportedWallets.filter(id => id !== 'gnosis'),
      EXTERNAL_LINKS
    };
  }
});
