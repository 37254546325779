import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-990847a4")
const _hoisted_1 = { class: "action-step-container" }
const _hoisted_2 = { class: "action-step-inner" }
const _hoisted_3 = { class: "action-step text-green-500" }
const _hoisted_4 = {
  key: 1,
  class: "text-gray-500 dark:text-gray-400"
}
const _hoisted_5 = { class: "ml-3 flex-1" }
const _hoisted_6 = {
  key: 0,
  class: "text-gray-400"
}
const _hoisted_7 = { class: "w-28" }
const _hoisted_8 = { key: 1 }
_popScopeId()

import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';

type Props = {
  stepNumber: string;
  complete: boolean;
  headline: string;
  subHeadline?: string;
  buttonText: string;
  buttonTextLoading: string;
  loading: boolean;
  disabled: boolean;
};


export default _defineComponent({
  props: {
    stepNumber: { type: String, required: true },
    complete: { type: Boolean, required: true },
    headline: { type: String, required: true },
    subHeadline: { type: String, required: false },
    buttonText: { type: String, required: true },
    buttonTextLoading: { type: String, required: true },
    loading: { type: Boolean, required: true },
    disabled: { type: Boolean, required: true }
  } as unknown as undefined,
  emits: ["buttonClick"] as unknown as undefined,
  setup(__props: {
  stepNumber: string;
  complete: boolean;
  headline: string;
  subHeadline?: string;
  buttonText: string;
  buttonTextLoading: string;
  loading: boolean;
  disabled: boolean;
}, { emit }: { emit: ({
  (e: 'buttonClick'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (props.complete)
          ? (_openBlock(), _createBlock(BalIcon, {
              key: 0,
              name: "check",
              class: "text-green-500"
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.stepNumber), 1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, _toDisplayString(props.headline), 1),
        (props.subHeadline)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(props.subHeadline), 1))
          : _createCommentVNode("", true)
      ]),
      (!props.complete)
        ? (_openBlock(), _createBlock(BalBtn, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('buttonClick'))),
            disabled: props.disabled,
            size: "sm",
            loading: props.loading,
            "loading-label": props.buttonTextLoading
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(props.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "loading-label"]))
        : _createCommentVNode("", true),
      (props.complete)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "completeContent")
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "underContent")
  ]))
}
}

})