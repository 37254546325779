
import useAlerts, { Alert, AlertType } from '@/composables/useAlerts';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppNavAlert',

  props: {
    alert: { type: Object as PropType<Alert>, required: true }
  },

  setup(props) {
    const { removeAlert } = useAlerts();

    const colorClass = computed(() => {
      switch (props.alert.type) {
        case AlertType.ERROR:
          return 'bg-red-600 text-white';
        default:
          return 'bg-black text-white';
      }
    });

    const iconName = computed(() => {
      switch (props.alert.type) {
        case AlertType.ERROR:
          return 'alert-triangle';
        default:
          return 'info';
      }
    });

    const classes = computed(() => {
      return {
        [colorClass.value]: true
      };
    });

    function handleClose() {
      removeAlert(props.alert.id);
    }

    return { classes, iconName, handleClose };
  }
});
