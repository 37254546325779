import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["onClick", "color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("button", _mergeProps({
        key: option.value,
        onClick: ($event: any) => (_ctx.onSelect(option)),
        outline: "",
        size: "sm",
        class: "capitalize mr-2 w-18 bal-btn-5"
      }, _ctx.attrs_, {
        color: _ctx.modelValue === option.value ? '#3D3FA9' : ''
      }), _toDisplayString('best' === option.label ? _ctx.$t(option.label) : option.label), 17, _hoisted_2))
    }), 128))
  ]))
}