import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-010c1afa")
const _hoisted_1 = { class: "step-card-container" }
const _hoisted_2 = { class: "ml-3 flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "ml-4"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-6 relative"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, { class: "mb-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['step-card-step', $props.complete ? 'bg-gold-500 border-black' : ''])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([$props.complete ? 'text-black' : 'text-gray-400'])
          }, _toDisplayString($props.stepNumber), 3)
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString($props.title), 1)
        ]),
        (_ctx.$slots.right)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "right", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.$slots.content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}