
import useFathom from '@/composables/useFathom';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import useApp from '@/composables/useApp';
import useWeb3 from '@/services/web3/useWeb3';
import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import AppNavOtherItems from '@/embr/components/navs/AppNavOtherItems.vue';

export default defineComponent({
  name: 'AppNavToggle',
  components: {},
  props: {
    darkModeBg: { type: String, default: '800' }
  },

  setup() {
    const route = useRoute();
    const { appLoading } = useApp();
    const { account } = useWeb3();
    const activeClasses = 'bg-black text-white dark:bg-gray-800';
    const isTradePage = computed(() => route.name === 'trade');
    const isFarmPage = computed(() => String(route.name).startsWith('farm'));
    const isEmbrPage = computed(() => route.name === 'embr');
    const isPortfolioPage = computed(() => route.name === 'my-portfolio');
    const isStakePage = computed(() => route.name === 'stake');
    const isInvestPage = computed(
      () => route.name === 'invest' || String(route.name).startsWith('pool')
    );
    const isHomePage = computed(
      () =>
        !isTradePage.value &&
        !isFarmPage.value &&
        !isEmbrPage.value &&
        !isInvestPage.value
    );
    const { trackGoal, Goals } = useFathom();

    const isLoggedIn = computed(() => !appLoading.value && !!account.value);

    return {
      isTradePage,
      activeClasses,
      trackGoal,
      Goals,
      isLoggedIn,
      isPortfolioPage,
      isStakePage,
      isInvestPage
    };
  }
});
