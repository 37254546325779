import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-379cdc7a")
const _hoisted_1 = {
  key: 0,
  class: "balance-indicator",
  title: "You have tokens that can be invested"
}
const _hoisted_2 = { class: "pill-text" }
_popScopeId()

import { withDefaults } from 'vue';

type Props = {
  hasBalance: boolean;
  symbol: string;
  isSelected: boolean;
};


export default _defineComponent({
  props: {
    hasBalance: { type: Boolean, required: true, default: false },
    symbol: { type: String, required: true },
    isSelected: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  hasBalance: boolean;
  symbol: string;
  isSelected: boolean;
}) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'pill',
      {
        'pill-selected': __props.isSelected
      }
    ])
  }, [
    (__props.hasBalance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.symbol), 1)
  ], 2))
}
}

})