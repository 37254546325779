
import { computed, defineComponent, PropType, ref } from 'vue';
import numeral from 'numeral';
import * as echarts from 'echarts/core';
import ECharts from 'vue-echarts';
import useTailwind from '@/composables/useTailwind';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import { chartColors2 } from '@/embr/constants/colors';
import { UserTokenData } from '@/embr/services/embr/embr-types';
import { sumBy } from 'lodash';
import useNumbers from '@/composables/useNumbers';

export default defineComponent({
  props: {
    assets: {
      type: Array as PropType<UserTokenData[]>,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  components: {
    BalCard,
    ECharts
  },
  setup(props) {
    const chartInstance = ref<echarts.ECharts>();
    const tailwind = useTailwind();
    const { fNum } = useNumbers();
    const showAll = ref(false);

    const mainAssets = computed(() => props.assets.slice(0, 4));

    const chartConfig = computed(() => {
      const majorAssets = props.assets.filter(
        asset => asset.percentOfPortfolio >= 0.03
      );
      const others = props.assets.filter(
        asset => asset.percentOfPortfolio < 0.03
      );
      const othersValue = sumBy(others, 'totalValue');

      return {
        tooltip: {
          trigger: 'item',
          backgroundColor: tailwind.theme.colors.gray['800'],
          borderColor: tailwind.theme.colors.gray['800'],
          formatter: param => {
            return `
          <div class='flex flex-col font-body bg-white dark:bg-gray-800 dark:text-white'>
            <span>${param.marker} ${
              param.name
            }<span class='font-medium ml-2'>${fNum(param.value, 'usd')}
                  </span>
                </span>
          </div>
          `;
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: 'My Assets',
            type: 'pie',
            radius: ['45%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: tailwind.theme.colors.gray['850'],
              borderWidth: 2
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold'
              }
            },
            label: {
              position: 'inside',
              fontWeight: 'bold',
              color: '#FFFFFF'
            },
            data: [
              ...majorAssets.map(asset => ({
                name: asset.symbol,
                value: Math.round(asset.totalValue * 100) / 100
              })),
              ...(othersValue > 0
                ? [
                    {
                      name: 'Other',
                      value: othersValue
                    }
                  ]
                : [])
            ],
            color: chartColors2
          }
        ]
      };
    });

    function toggleShowAll() {
      showAll.value = !showAll.value;
    }

    return {
      chartInstance,
      numeral,
      chartConfig,
      mainAssets,
      fNum,
      chartColors2,
      showAll,
      toggleShowAll
    };
  }
});
