import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/embr/assets/images/candlestickchart.png'
import _imports_1 from '@/embr/assets/images/linechart.png'


_pushScopeId("data-v-5478a871")
const _hoisted_1 = { class: "relative h-full bg-transparent p-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "h-full w-full flex justify-center items-center"
}
const _hoisted_4 = { class: "text-sm text-gray-400" }
const _hoisted_5 = {
  key: 2,
  class: "h-full w-full flex justify-center items-center"
}
const _hoisted_6 = { class: "text-sm text-gray-400 text-center" }
const _hoisted_7 = {
  key: 3,
  class: "flex-col"
}
const _hoisted_8 = {
  key: 0,
  src: _imports_0,
  width: "20",
  class: "invert-image-color"
}
const _hoisted_9 = {
  key: 1,
  src: _imports_1,
  width: "20",
  class: "invert-image-color"
}
const _hoisted_10 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "text-sm text-gray-200 mr-4" }
const _hoisted_15 = { class: "text-sm text-gray-200" }
const _hoisted_16 = {
  key: 4,
  class: "-mt-2 lg:mt-2"
}
const _hoisted_17 = { class: "text-sm text-gray-200 w-full flex justify-end" }
_popScopeId()

import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useTokens from '@/composables/useTokens';
import { coingeckoService } from '@/services/coingecko/coingecko.service';
import { useQuery } from 'vue-query';
import {
  Dictionary,
  mapKeys,
  mapValues,
  maxBy,
  minBy,
  pickBy,
  toPairs
} from 'lodash';
import { fromUnixTime, format } from 'date-fns';
import useTailwind from '@/composables/useTailwind';
import useBreakpoints from '@/composables/useBreakpoints';
import { useTradeState } from '@/composables/trade/useTradeState';
import { getAddress } from '@ethersproject/address';
import QUERY_KEYS from '@/constants/queryKeys';
import useWeb3 from '@/services/web3/useWeb3';

type Props = {
  isModal?: boolean;
  onCloseModal?: () => void;
  toggleModal: () => void;
};


export default _defineComponent({
  props: {
    isModal: { type: Boolean, required: false },
    onCloseModal: { type: Function, required: false },
    toggleModal: { type: Function, required: true }
  } as unknown as undefined,
  setup(__props: {
  isModal?: boolean;
  onCloseModal?: () => void;
  toggleModal: () => void;
}) {

const props = __props
async function getPairPriceData(
  inputAsset: string,
  outputAsset: string,
  nativeAsset: string,
  wrappedNativeAsset: string,
  days: number
) {
  let _inputAsset =
    inputAsset === nativeAsset ? wrappedNativeAsset : inputAsset;
  let _outputAsset =
    outputAsset === nativeAsset ? wrappedNativeAsset : outputAsset;
  const aggregateBy = days === 1 ? 'hour' : 'day';
  const inputAssetData = await coingeckoService.prices.getTokensHistorical(
    [_inputAsset],
    days,
    1,
    aggregateBy
  );

  const outputAssetData = await coingeckoService.prices.getTokensHistorical(
    [_outputAsset],
    days,
    1,
    aggregateBy
  );

  const calculatedPricing = mapValues(inputAssetData, (value, timestamp) => {
    if (!outputAssetData[timestamp]) return null;
    return (1 / value[0]) * outputAssetData[timestamp][0];
  });

  const calculatedPricingNoNulls = pickBy(calculatedPricing) as Dictionary<
    number
  >;

  const formatTimestamps = mapKeys(
    calculatedPricingNoNulls,
    (_, timestamp: any) =>
      format(fromUnixTime(timestamp / 1000), 'yyyy/MM/dd HH:mm')
  );

  return toPairs(formatTimestamps);
}

const chartTimespans = [
  {
    option: '1D',
    value: 1
  },
  {
    option: '1W',
    value: 7
  },
  {
    option: '1M',
    value: 30
  },
  {
    option: '1Y',
    value: 365
  },
  {
    option: 'All',
    value: 1000
  }
];

const chartCandleTimespans = [
  {
    option: '1D',
    value: 1
  },
  {
    option: '1W',
    value: 7
  },
  {
    option: '2W',
    value: 14
  },
  {
    option: '1M',
    value: 30
  },
  {
    option: '3M',
    value: 90
  },
  {
    option: '6M',
    value: 180
  },
  {
    option: '1Y',
    value: 365
  },
  {
    option: 'All',
    value: "max"
  }
];


const { upToLargeBreakpoint } = useBreakpoints();
const store = useStore();
const { tokens, wrappedNativeAsset, nativeAsset } = useTokens();
const { tokenInAddress, tokenOutAddress } = useTradeState();
const tailwind = useTailwind();
const { chainId: userNetworkId } = useWeb3();

const isCandlestick = ref(false);

function toggleCandlestickButton(): void {
  isCandlestick.value = !isCandlestick.value;
}

const chartHeight = ref(
  upToLargeBreakpoint ? (props.isModal ? 250 : 150) : props.isModal ? 250 : 100
);
const activeTimespan = ref(chartTimespans[0]);
const activeCandleTimespan = ref(chartCandleTimespans[0]);
const appLoading = computed(() => store.state.app.loading);

const inputSym = computed(() => {
  if (tokenInAddress.value === '') return 'Unknown';
  return tokens.value[getAddress(tokenInAddress.value)]?.symbol;
});
const outputSym = computed(() => {
  if (tokenOutAddress.value === '') return 'Unknown';
  return tokens.value[getAddress(tokenOutAddress.value)]?.symbol;
});

const dataMin = computed(() => {
  return (minBy(priceData.value || [], v => v[1]) || [])[1] || 0;
});

const dataMax = computed(() => {
  return (maxBy(priceData.value || [], v => v[1]) || [])[1] || 0;
});

const {
  isLoading: isLoadingPriceData,
  data: priceData,
  error: failedToLoadPriceData
} = useQuery(
  QUERY_KEYS.Tokens.PairPriceData(
    tokenOutAddress,
    tokenInAddress,
    activeTimespan,
    userNetworkId,
    nativeAsset,
    wrappedNativeAsset
  ),
  () =>
    getPairPriceData(
      tokenOutAddress.value,
      tokenInAddress.value,
      nativeAsset?.address,
      wrappedNativeAsset.value?.address,
      activeTimespan.value.value
    ),
  reactive({
    retry: false,
    // when refetch on window focus in enabled, it causes a flash
    // in the loading state of the card which is jarring. disabling it
    refetchOnWindowFocus: false
  })
);

const toggle = () => {
  props.toggleModal();
};

const chartData = computed(() => [
  {
    name: `${inputSym.value}/${outputSym.value}`,
    values: priceData.value || []
  }
]);

const isNegativeTrend = computed(() => {
  const _priceData = priceData.value || [];
  if (_priceData.length > 2) {
    if (
      _priceData[_priceData.length - 1][1] <
      _priceData[_priceData.length - 2][1]
    ) {
      return true;
    }
  }
  return false;
});

const chartColors = computed(() => {
  let color = tailwind.theme.colors.green['400'];
  if (isNegativeTrend.value) color = tailwind.theme.colors.red['400'];
  return [color];
});

const chartGrid = computed(() => {
  return {
    left: '2.5%',
    right: '0',
    top: '10%',
    bottom: '15%',
    containLabel: false
  };
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalLineChart = _resolveComponent("BalLineChart")!
  const _component_WaveCandleStickChart = _resolveComponent("WaveCandleStickChart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      '',
      {
        'h-40 lg:h-56': !__props.isModal,
        'h-full lg:h-full': __props.isModal
      }
    ])
  }, [
    (_unref(isLoadingPriceData))
      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
          key: 0,
          class: _normalizeClass({
        'h-64': !__props.isModal,
        'h-112': __props.isModal
      })
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!_unref(failedToLoadPriceData) && !(_unref(isLoadingPriceData) || _unref(appLoading)))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      (_unref(failedToLoadPriceData) && _unref(tokenOutAddress))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('insufficientData')), 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(failedToLoadPriceData) && !_unref(tokenOutAddress))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('chooseAPair')), 1)
          ]))
        : _createCommentVNode("", true),
      (!_unref(failedToLoadPriceData) && !_unref(isLoadingPriceData))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (!_unref(failedToLoadPriceData) && !(_unref(isLoadingPriceData) || _unref(appLoading)))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: toggleCandlestickButton,
                  class: "maximise m-4 flex justify-center items-center bal-btn-4"
                }, [
                  (!isCandlestick.value)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                    : _createCommentVNode("", true),
                  (isCandlestick.value)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (!isCandlestick.value)
              ? (_openBlock(), _createBlock(_component_BalLineChart, {
                  key: 1,
                  data: _unref(chartData),
                  height: chartHeight.value,
                  "show-legend": false,
                  color: _unref(chartColors),
                  "custom-grid": _unref(chartGrid),
                  "axis-label-formatter": { yAxis: '0.000000' },
                  "wrapper-class": [
              'flex flex-row lg:flex-col',
              {
                'flex-row': !__props.isModal,
                'flex-col': __props.isModal
              }
            ],
                  "show-tooltip": !_unref(upToLargeBreakpoint) || __props.isModal,
                  "hide-y-axis": "",
                  "hide-x-axis": "",
                  "show-header": "",
                  "use-min-max": ""
                }, null, 8, ["data", "height", "color", "custom-grid", "axis-label-formatter", "wrapper-class", "show-tooltip"]))
              : _createCommentVNode("", true),
            (isCandlestick.value)
              ? (_openBlock(), _createBlock(_component_WaveCandleStickChart, {
                  key: 2,
                  baseCurrency: _unref(chartData)[0].name.split('/')[0],
                  vsCurrency: _unref(chartData)[0].name.split('/')[1],
                  otherCurrency: "usd",
                  days: activeCandleTimespan.value.value
                }, null, 8, ["baseCurrency", "vsCurrency", "days"]))
              : _createCommentVNode("", true),
            (__props.isModal)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass([
              'w-full flex justify-between mt-6',
              {
                'flex-col': __props.isModal
              }
            ])
                }, [
                  (isCandlestick.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(chartCandleTimespans, (timespan) => {
                          return _createElementVNode("button", {
                            onClick: ($event: any) => (activeCandleTimespan.value = timespan),
                            key: timespan.value,
                            class: _normalizeClass([
                  'py-1 px-2 text-sm rounded-lg mr-2 bal-btn-4',
                  {
                    'text-white': activeCandleTimespan.value.value === timespan.value,
                    'text-gray-200': activeCandleTimespan.value.value !== timespan.value,
                    'bg-green-400':
                      !_unref(isNegativeTrend) &&
                      activeCandleTimespan.value.value === timespan.value,
                    'bg-red-400':
                      _unref(isNegativeTrend) &&
                      activeCandleTimespan.value.value === timespan.value,
                    'hover:bg-red-200': _unref(isNegativeTrend),
                    'hover:bg-green-200': !_unref(isNegativeTrend)
                  }
                ])
                          }, _toDisplayString(timespan.option), 11, _hoisted_11)
                        }), 64))
                      ]))
                    : _createCommentVNode("", true),
                  (!isCandlestick.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(chartTimespans, (timespan) => {
                          return _createElementVNode("button", {
                            onClick: ($event: any) => (activeTimespan.value = timespan),
                            key: timespan.value,
                            class: _normalizeClass([
                  'py-1 px-2 text-sm rounded-lg mr-2 bal-btn-4',
                  {
                    'text-white': activeTimespan.value.value === timespan.value,
                    'text-gray-200': activeTimespan.value.value !== timespan.value,
                    'bg-green-400':
                      !_unref(isNegativeTrend) &&
                      activeTimespan.value.value === timespan.value,
                    'bg-red-400':
                      _unref(isNegativeTrend) &&
                      activeTimespan.value.value === timespan.value,
                    'hover:bg-red-200': _unref(isNegativeTrend),
                    'hover:bg-green-200': !_unref(isNegativeTrend)
                  }
                ])
                          }, _toDisplayString(timespan.option), 11, _hoisted_13)
                        }), 64))
                      ]))
                    : _createCommentVNode("", true),
                  (!isCandlestick.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({ 'mt-4': __props.isModal })
                      }, [
                        _createElementVNode("span", _hoisted_14, "Low: " + _toDisplayString(_unref(dataMin).toPrecision(6)), 1),
                        _createElementVNode("span", _hoisted_15, "High: " + _toDisplayString(_unref(dataMax).toPrecision(6)), 1)
                      ], 2))
                    : _createCommentVNode("", true)
                ], 2))
              : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(activeTimespan.value.option), 1)
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})