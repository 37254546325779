
import { computed, defineComponent, PropType } from 'vue';
import useNumbers from '@/composables/useNumbers';
import useBreakpoints from '@/composables/useBreakpoints';
import { Alert } from '@/composables/useAlerts';
import useProtocolDataQuery from '@/embr/composables/queries/useProtocolDataQuery';

export default defineComponent({
  name: 'AppNavOtherItemsFooter',

  props: {
    Alert: { type: Object as PropType<Alert>, required: true }
  },

  setup() {
    const { fNum } = useNumbers();
    const { upToLargeBreakpoint } = useBreakpoints();

    const protocolDataQuery = useProtocolDataQuery();
    const tvl = computed(
      () => protocolDataQuery.data?.value?.totalLiquidity || 0
    );

    const embrPrice = computed(
      () => protocolDataQuery.data?.value?.embrPrice || 0
    );
    const circulatingSupply = computed(
      () => protocolDataQuery.data.value?.circulatingSupply || 0
    );
    const marketCap = computed(() => {
      return embrPrice.value * circulatingSupply.value;
    });
    const loading = computed(() => protocolDataQuery.isLoading.value);

    return {
      fNum,
      upToLargeBreakpoint,
      embrPrice,
      tvl,
      circulatingSupply,
      marketCap,
      loading
    };
  }
});
