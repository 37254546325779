import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-purple-500 relative" }
const _hoisted_2 = { class: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpinnerIcon = _resolveComponent("SpinnerIcon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.count), 1),
    _createVNode(_component_SpinnerIcon, { class: "h-6 w-6 animate-spin" })
  ]))
}