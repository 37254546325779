import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"
import _imports_0 from '@/embr/assets/images/Notifications-bell.png'


const _hoisted_1 = ["size", "circle"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  width: "28"
}
const _hoisted_3 = { class: "p-3 w-full flex items-center justify-between border-b dark:border-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "bg-gray-100 dark:bg-gray-700 my-3 h-px"
}
const _hoisted_5 = { class: "w-full p-3 rounded-b-lg bg-white dark:bg-gray-800 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivityCounter = _resolveComponent("ActivityCounter")!
  const _component_ActivityRows = _resolveComponent("ActivityRows")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createBlock(_component_BalPopover, { "no-pad": "" }, {
    activator: _withCtx(() => [
      _createElementVNode("div", {
        color: "transparent",
        size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
        class: "mr-2 relative bal-btn-2",
        circle: _ctx.upToLargeBreakpoint
      }, [
        (_ctx.pendingTransactions.length === 0)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createBlock(_component_ActivityCounter, {
              key: 1,
              count: _ctx.pendingTransactions.length
            }, null, 8, ["count"]))
      ], 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_BalCard, {
        class: "w-72",
        noPad: "",
        noBorder: ""
      }, _createSlots({
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.$t('recentActivityTitle')), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(['p-3', { 'h-72 overflow-auto': _ctx.transactions.length > 5 }])
          }, [
            (_ctx.transactions.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ActivityRows, {
                    transactions: _ctx.pendingTransactions,
                    "get-explorer-link": _ctx.getExplorerLink,
                    "is-successful-transaction": _ctx.isSuccessfulTransaction,
                    "is-pending-transaction-status": _ctx.isPendingTransactionStatus,
                    "cancel-order": _ctx.cancelOrder
                  }, null, 8, ["transactions", "get-explorer-link", "is-successful-transaction", "is-pending-transaction-status", "cancel-order"]),
                  (
              _ctx.pendingTransactions.length > 0 && _ctx.finalizedTransactions.length > 0
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ActivityRows, {
                    transactions: _ctx.finalizedTransactions,
                    "get-explorer-link": _ctx.getExplorerLink,
                    "is-successful-transaction": _ctx.isSuccessfulTransaction,
                    "is-pending-transaction-status": _ctx.isPendingTransactionStatus,
                    "cancel-order": _ctx.cancelOrder
                  }, null, 8, ["transactions", "get-explorer-link", "is-successful-transaction", "is-pending-transaction-status", "cancel-order"])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('noRecentActivity')), 1)
                ], 64))
          ], 2)
        ]),
        _: 2
      }, [
        (_ctx.transactions.length > 0)
          ? {
              name: "footer",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearAllTransactions())),
                    class: "text-white"
                  }, _toDisplayString(_ctx.$t('clearTransactions')), 1)
                ])
              ])
            }
          : undefined
      ]), 1024)
    ]),
    _: 1
  }))
}