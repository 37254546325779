import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, Teleport as _Teleport, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2ae66afa")
const _hoisted_1 = { class: "w-full flex items-center justify-between" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = {
  key: 5,
  class: "mt-6 text-sm flex items-center"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "text-sm pl-2" }
const _hoisted_6 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TradeSettingsPopover = _resolveComponent("TradeSettingsPopover")!
  const _component_TradePair = _resolveComponent("TradePair")!
  const _component_GasReimbursement = _resolveComponent("GasReimbursement")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalToggle = _resolveComponent("BalToggle")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_TradePreviewModalGP = _resolveComponent("TradePreviewModalGP")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, {
      class: "relative card-container",
      shadow: _ctx.tradeCardShadow,
      "no-border": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_TradeSettingsPopover, {
            context: _ctx.TradeSettingsContext.trade,
            isGasless: _ctx.trading.tradeGasless.value
          }, null, 8, ["context", "isGasless"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_TradePair, {
            tokenInAmount: _ctx.tokenInAmount,
            "onUpdate:tokenInAmount": _cache[0] || (_cache[0] = ($event: any) => (_ctx.tokenInAmount = $event)),
            tokenInAddress: _ctx.tokenInAddress,
            "onUpdate:tokenInAddress": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tokenInAddress = $event)),
            tokenOutAmount: _ctx.tokenOutAmount,
            "onUpdate:tokenOutAmount": _cache[2] || (_cache[2] = ($event: any) => (_ctx.tokenOutAmount = $event)),
            tokenOutAddress: _ctx.tokenOutAddress,
            "onUpdate:tokenOutAddress": _cache[3] || (_cache[3] = ($event: any) => (_ctx.tokenOutAddress = $event)),
            exactIn: _ctx.exactIn,
            "onUpdate:exactIn": _cache[4] || (_cache[4] = ($event: any) => (_ctx.exactIn = $event)),
            tradeLoading: 
          _ctx.trading.isBalancerTrade.value ? _ctx.trading.isLoading.value : false
        ,
            effectivePriceMessage: _ctx.trading.effectivePriceMessage,
            onAmountChange: _ctx.trading.handleAmountChange,
            class: "mb-4"
          }, null, 8, ["tokenInAmount", "tokenInAddress", "tokenOutAmount", "tokenOutAddress", "exactIn", "tradeLoading", "effectivePriceMessage", "onAmountChange"]),
          (!_ctx.ENABLE_LEGACY_TRADE_INTERFACE && _ctx.trading.isBalancerTrade.value)
            ? (_openBlock(), _createBlock(_component_GasReimbursement, {
                key: 0,
                class: "mb-5",
                "address-in": _ctx.tokenInAddress,
                "address-out": _ctx.tokenOutAddress,
                sorReturn: _ctx.trading.sor.sorReturn.value
              }, null, 8, ["address-in", "address-out", "sorReturn"]))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createBlock(_component_BalAlert, {
                key: 1,
                class: "p-3 mb-4",
                type: "error",
                size: "sm",
                title: _ctx.error.header,
                description: _ctx.error.body,
                "action-label": _ctx.error.label,
                block: "",
                onActionClick: _ctx.handleErrorButtonClick
              }, null, 8, ["title", "description", "action-label", "onActionClick"]))
            : (_ctx.warning)
              ? (_openBlock(), _createBlock(_component_BalAlert, {
                  key: 2,
                  class: "p-3 mb-4",
                  type: "warning",
                  size: "sm",
                  title: _ctx.warning.header,
                  description: _ctx.warning.body,
                  block: ""
                }, null, 8, ["title", "description"]))
              : _createCommentVNode("", true),
          (_ctx.trading.isLoading.value)
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 3,
                loading: "",
                disabled: "",
                "loading-label": 
          _ctx.trading.isGnosisTrade.value ? _ctx.$t('loadingBestPrice') : _ctx.$t('loading')
        ,
                block: ""
              }, null, 8, ["loading-label"]))
            : (_openBlock(), _createBlock(_component_BalBtn, {
                key: 4,
                label: _ctx.$t('preview'),
                disabled: _ctx.tradeDisabled,
                color: "gradient",
                block: "",
                onClick: _withModifiers(_ctx.handlePreviewButton, ["prevent"])
              }, null, 8, ["label", "disabled", "onClick"])),
          (
          !_ctx.ENABLE_LEGACY_TRADE_INTERFACE &&
            _ctx.trading.isGnosisSupportedOnNetwork.value
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_BalTooltip, {
                  width: "64",
                  disabled: !_ctx.trading.isGaslessTradingDisabled.value
                }, {
                  activator: _withCtx(() => [
                    _createVNode(_component_BalToggle, {
                      name: "tradeGasless",
                      checked: _ctx.trading.tradeGasless.value,
                      onToggle: _ctx.trading.toggleTradeGasless,
                      disabled: _ctx.trading.isGaslessTradingDisabled.value
                    }, null, 8, ["checked", "onToggle", "disabled"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      textContent: _toDisplayString(
              _ctx.trading.isWrapUnwrapTrade.value
                ? _ctx.$t('tradeGaslessToggle.disabledTooltip.wrapUnwrap')
                : _ctx.$t('tradeGaslessToggle.disabledTooltip.eth')
            )
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('tradeGaslessToggle.label')), 1),
                _createVNode(_component_BalTooltip, { width: "64" }, {
                  activator: _withCtx(() => [
                    _createVNode(_component_BalIcon, {
                      name: "info",
                      size: "xs",
                      class: "text-gray-400 ml-1 flex"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t('tradeGaslessToggle.tooltip')
                    }, null, 8, _hoisted_6)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["shadow"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.modalTradePreviewIsOpen)
        ? (_openBlock(), _createBlock(_component_TradePreviewModalGP, {
            key: 0,
            "address-in": _ctx.tokenInAddress,
            "amount-in": _ctx.tokenInAmount,
            "address-out": _ctx.tokenOutAddress,
            "amount-out": _ctx.tokenOutAmount,
            trading: _ctx.trading,
            onTrade: _ctx.trade,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modalTradePreviewIsOpen = false))
          }, null, 8, ["address-in", "amount-in", "address-out", "amount-out", "trading", "onTrade"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}