import { getAddress } from '@ethersproject/address';

import { isStable } from '@/composables/usePool';

import Service from '../../balancer-subgraph.service';
import queryBuilder, { pastPoolsQuery } from './query';
import { DecoratedPool, Pool, PoolToken, QueryBuilder } from '../../types';

import { Network } from '@/composables/useNetwork';
import { configService as _configService } from '@/services/config/config.service';
import PoolService from '@/services/pool/pool.service';
import axios from 'axios';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export default class Pools {
  service: Service;
  query: QueryBuilder;
  networkId: Network;

  constructor(
    service: Service,
    query: QueryBuilder = queryBuilder,
    private readonly configService = _configService,
    private readonly poolServiceClass = PoolService
  ) {
    this.service = service;
    this.query = query;
    this.networkId = configService.env.NETWORK;
  }

  public async get(): Promise<Pool[]> {
    const query = this.query();

    try {
      const {
        data: { data }
      } = await axios.post(this.configService.network.poolsUrlV2, {
        query: jsonToGraphQLQuery({ query })
      });

      return data.pools;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getPastPools(): Promise<Pool[]> {
    const query = pastPoolsQuery();

    try {
      const {
        data: { data }
      } = await axios.post(this.configService.network.poolsUrlV2, {
        query: jsonToGraphQLQuery({ query })
      });

      return data.poolsPastPools;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getPoolDataFromTxHash(
    txHash: string
  ): Promise<{
    id: string;
    address: string;
    poolType: string;
    owner: string;
  } | null> {
    try {
      const {
        data: { data }
      } = await axios.post(this.configService.network.subgraph, {
        query: jsonToGraphQLQuery({
          query: {
            pools: {
              __args: { where: { tx: txHash } },
              id: true,
              address: true,
              poolType: true,
              owner: true
            }
          }
        })
      });

      return data?.pools[0] || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async decorate(pools: Pool[]): Promise<DecoratedPool[]> {
    return this.serialize(pools);
  }

  private async serialize(pools: Pool[]): Promise<DecoratedPool[]> {
    const promises = pools.map(async pool => {

      pool.address = this.addressFor(pool.id);
      pool.tokenAddresses = pool.tokensList.map(t => getAddress(t));
      pool.tokens = this.formatPoolTokens(pool);

      return pool;
    });

    return Promise.all(promises);
  }

  private formatPoolTokens(pool: Pool): PoolToken[] {
    const tokens = pool.tokens.map(token => ({
      ...token,
      address: getAddress(token.address)
    }));

    if (isStable(pool.poolType)) return tokens;

    return tokens.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
  }

  public addressFor(poolId: string): string {
    return getAddress(poolId.slice(0, 42));
  }
}
