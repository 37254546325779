
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from 'vue';
import { TransitionPresets, useTransition } from '@vueuse/core';

import useNotifications, { Notification } from '@/composables/useNotifications';

export default defineComponent({
  name: 'Notification',

  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    }
  },

  setup(props) {
    // COMPOSABLES
    const totalProgress = ref(1);

    const progress = useTransition(totalProgress, {
      duration: props.notification.autoCloseAfterMs,
      transition: TransitionPresets.linear
    });

    let notificationTimeout: NodeJS.Timeout;

    const { notifications, removeNotification } = useNotifications();

    // METHODS
    function closeNotification() {
      removeNotification(props.notification.id);
    }

    onMounted(() => {
      totalProgress.value = 0;

      if (props.notification.autoCloseAfterMs) {
        notificationTimeout = setTimeout(() => {
          closeNotification();
        }, props.notification.autoCloseAfterMs);
      }
    });

    onUnmounted(() => {
      if (notificationTimeout != null) {
        clearTimeout(notificationTimeout);
      }
    });

    // COMPUTED
    const progressClasses = computed(() => {
      let bgClasses = 'bg-purple-600 dark:bg-purple-500';

      if (props.notification.type === 'success') {
        bgClasses = 'bg-gold-500 dark:bg-gold-500';
      } else if (props.notification.type === 'error') {
        bgClasses = 'bg-red-500 dark:bg-red-600';
      }

      return `
          absolute bottom-0 left-0 opacity-80 w-0 transition duration-300 ease-linear h-1 ${bgClasses}
        `;
    });

    return {
      // methods
      closeNotification,

      // computed
      notifications,
      progressClasses,
      progress
    };
  }
});
