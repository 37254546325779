import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-dea3f6c2")
const _hoisted_1 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_2 = { class: "relative center-col-mh" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-11 lg:col-span-3"
}
_popScopeId()

import { computed, onMounted, ref, watch } from 'vue';

import ChooseWeights from '@/components/cards/CreatePool/ChooseWeights.vue';
import PoolSummary from '@/components/cards/CreatePool/PoolSummary.vue';
//import PoolName from '@/components/cards/CreatePool/PoolName.vue';
import PoolFees from '@/components/cards/CreatePool/PoolFees.vue';
import SimilarPools from '@/components/cards/CreatePool/SimilarPools.vue';
import InitialLiquidity from '@/components/cards/CreatePool/InitialLiquidity.vue';
import SimilarPoolsCompact from '@/components/cards/CreatePool/SimilarPoolsCompact.vue';
import PreviewPool from '@/components/cards/CreatePool/PreviewPool.vue';
import BalVerticalSteps from '@/components/_global/BalVerticalSteps/BalVerticalSteps.vue';
import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import Col3Layout from '@/components/layouts/Col3Layout.vue';

import anime from 'animejs';

import useApp from '@/composables/useApp';
import usePoolCreation from '@/composables/pools/usePoolCreation';
import { StepState } from '@/types';
import useBreakpoints from '@/composables/useBreakpoints';


export default _defineComponent({
  setup(__props) {

const initialAnimateProps = {
  opacity: 0,
  translateY: '100px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
};

const entryAnimateProps = {
  opacity: 1,
  translateY: '0px',
  position: 'relative'
};
const exitAnimateProps = {
  opacity: 0,
  translateY: '-100px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
};

/**
 * STATE
 */
const accordionWrapper = ref<HTMLElement>();
const hasCompletedMountAnimation = ref(false);
const prevWrapperHeight = ref(0);
/**
 * COMPOSABLES
 */
const { appLoading } = useApp();
const {
  activeStep,
  similarPools,
  maxInitialLiquidity,
  setActiveStep,
  hasInjectedToken,
  seedTokens,
  totalLiquidity
} = usePoolCreation();
const { upToLargeBreakpoint, upToSmallBreakpoint } = useBreakpoints();

onMounted(() => {
  if (accordionWrapper.value) {
    anime.set(accordionWrapper.value, {
      opacity: 0
    });
  }
});

/**
 * COMPUTED
 */
const doSimilarPoolsExist = computed(() => similarPools.value.length > 0);

const steps = computed(() => [
  {
    tooltip: 'Choose tokens & weights',
    state: getStepState(0),
    label: 1
  },
  {
    tooltip: 'Set pool name / symbol',
    state: getStepState(1),
    label: 2
  },
  {
    tooltip: 'Set pool fees',
    state: getStepState(2),
    label: 3
  },
  {
    tooltip: 'Similar pools',
    state: StepState.Warning,
    isVisible: doSimilarPoolsExist.value && activeStep.value === 3
  },
  {
    tooltip: 'Set initial liquidity',
    state: getStepState(4),
    label: 4
  },
  {
    tooltip: 'Confirm pool creation',
    state: getStepState(5),
    label: 5
  }
]);

/**
 * FUNCTIONS
 */
function getStepState(idx: number) {
  if (activeStep.value === idx) {
    return StepState.Active;
  } else {
    if (activeStep.value > idx) {
      return StepState.Completed;
    } else {
      return StepState.Todo;
    }
  }
}

function setWrapperHeight(dimensions?: { width: number; height: number }) {
  // need to transform the accordion as everything is absolutely
  // positioned inside the AnimateHeight component
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  if (dimensions?.height) prevWrapperHeight.value = dimensions.height;

  let mobileOffset = 50;
  if (upToLargeBreakpoint.value) {
    if (validTokens.length >= 2 && maxInitialLiquidity.value < 20000) {
      mobileOffset += 90;
    }
    if (hasInjectedToken.value) {
      mobileOffset += upToSmallBreakpoint.value ? 145 : 160;
    }
  }
  anime({
    targets: accordionWrapper.value,
    translateY: `${prevWrapperHeight.value + mobileOffset}px`,
    easing: 'spring(0.4, 500, 9, 0)',
    complete: () => {
      if (!hasCompletedMountAnimation.value) {
        anime({
          targets: accordionWrapper.value,
          opacity: 1,
          complete: () => {
            hasCompletedMountAnimation.value = true;
          }
        });
      }
    }
  });
}

function handleNavigate(stepIndex: number) {
  setActiveStep(stepIndex);
}

/**
 * WATCHERS
 */

watch([hasInjectedToken, totalLiquidity], () => {
  setWrapperHeight();
});

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!

  return (_openBlock(), _createBlock(Col3Layout, {
    offsetGutters: "",
    mobileHideGutters: "",
    class: "mt-8"
  }, {
    gutterLeft: _withCtx(() => [
      (!_unref(upToLargeBreakpoint))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_unref(appLoading))
              ? (_openBlock(), _createBlock(_component_BalStack, {
                  key: 0,
                  vertical: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(BalVerticalSteps, {
                      title: "Create a weighted pool steps",
                      steps: _unref(steps),
                      onNavigate: handleNavigate
                    }, null, 8, ["steps"]),
                    _createVNode(AnimatePresence, {
                      isVisible: _unref(doSimilarPoolsExist) && _unref(activeStep) === 0
                    }, {
                      default: _withCtx(() => [
                        _createVNode(SimilarPoolsCompact)
                      ]),
                      _: 1
                    }, 8, ["isVisible"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    gutterRight: _withCtx(() => [
      (!_unref(upToLargeBreakpoint))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!_unref(appLoading))
              ? (_openBlock(), _createBlock(_component_BalStack, {
                  key: 0,
                  vertical: "",
                  spacing: "base"
                }, {
                  default: _withCtx(() => [
                    _createVNode(PoolSummary)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(AnimatePresence, {
          isVisible: !_unref(appLoading) && _unref(activeStep) === 0,
          initial: initialAnimateProps,
          animate: entryAnimateProps,
          exit: exitAnimateProps,
          onUpdateDimensions: setWrapperHeight
        }, {
          default: _withCtx(() => [
            _createVNode(ChooseWeights, { "onUpdate:height": setWrapperHeight })
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: !_unref(appLoading) && _unref(activeStep) === 3,
          initial: initialAnimateProps,
          animate: entryAnimateProps,
          exit: exitAnimateProps,
          onUpdateDimensions: setWrapperHeight
        }, {
          default: _withCtx(() => [
            _createVNode(PoolFees)
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: !_unref(appLoading) && _unref(activeStep) === 4 && _unref(similarPools).length > 0,
          initial: initialAnimateProps,
          animate: entryAnimateProps,
          exit: exitAnimateProps,
          onUpdateDimensions: setWrapperHeight
        }, {
          default: _withCtx(() => [
            _createVNode(SimilarPools)
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: !_unref(appLoading) && _unref(activeStep) === 4,
          initial: initialAnimateProps,
          animate: entryAnimateProps,
          exit: exitAnimateProps,
          onUpdateDimensions: setWrapperHeight
        }, {
          default: _withCtx(() => [
            _createVNode(InitialLiquidity)
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: !_unref(appLoading) && _unref(activeStep) === 5,
          initial: initialAnimateProps,
          animate: entryAnimateProps,
          exit: exitAnimateProps,
          onUpdateDimensions: setWrapperHeight
        }, {
          default: _withCtx(() => [
            _createVNode(PreviewPool)
          ]),
          _: 1
        }, 8, ["isVisible"]),
        (_unref(upToLargeBreakpoint))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: accordionWrapper,
              class: "pb-24"
            }, [
              _createVNode(_component_BalAccordion, { sections: [{ title: 'Pool summary', id: 'pool-summary' }] }, {
                "pool-summary": _withCtx(() => [
                  _createVNode(PoolSummary)
                ]),
                _: 1
              })
            ], 512))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})