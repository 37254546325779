
import useNumbers from '@/composables/useNumbers';
import anime from 'animejs';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import useTokens from '@/composables/useTokens';
import useUserSettings from '@/composables/useUserSettings';
import BalCard from '@/components/_global/BalCard/BalCard.vue';

export default {
  name: 'StepContainer',

  components: { BalCard },

  props: {
    stepNumber: { type: Number, required: true },
    title: { type: String, required: true },
    complete: { type: Boolean, required: true }
  },

  setup(props) {
    /**
     * COMPOSABLES
     */
    const { fNum } = useNumbers();

    const animateRef = ref();
    const { balances, prices } = useTokens();
    const { currency } = useUserSettings();

    /**
     * COMPUTED
     */
    const balance = computed(() => Number(balances.value[props.token.address]));
    const price = computed(() =>
      prices.value[props.token.address]
        ? prices.value[props.token.address][currency.value]
        : 0
    );
    const value = computed(() => balance.value * price.value);

    /**
     * CALLBACKS
     */
    onMounted(() => {
      anime({
        opacity: 1,
        targets: animateRef.value,
        delay: anime.stagger(100)
      });
    });

    onUnmounted(() => {
      anime({
        opacity: 0,
        targets: animateRef.value
      });
    });

    return {
      fNum,
      animateRef,
      balance,
      value
    };
  }
};
