import { configService } from '@/services/config/config.service';

export const NATIVE_ASSET_ADDRESS = configService.network.nativeAsset.address;

export const DEFAULT_TOKEN_DECIMALS = 18;

export const TOKENS = {
  Popular: {
    Symbols: {
      43114: ['DAI.e', 'USDC.e', 'EMBR', 'WAVAX'],
      250: ["DAI", "USDC", "WAVE", "WFTM"],
      106: ["DAI", "USDC", "WAVE", "WVLX"]
  }
  },
  AddressMap: {
    '1': {
      WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      BAL: '0xba100000625a3754423978a60c9317c58a424e3d'
    },
    '42': {
      WETH: '0xdFCeA9088c8A88A76FF74892C1457C17dfeef9C1',
      BAL: '0x41286Bb1D3E870f3F750eB7E1C25d7E48c8A1Ac7'
    },
    '137': {
      WETH: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      BAL: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3'
    },
    '42161': {
      WETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      BAL: '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8'
    },
    '250': {
      ETH: '0x0000000000000000000000000000000000000000',
      WETH: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      BAL: '0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e'
    },
    '4': {
      ETH: '0x0000000000000000000000000000000000000000',
      WETH: '0x80dD2B80FbcFB06505A301d732322e987380EcD6',
      BAL: '0xba100000625a3754423978a60c9317c58a424e3d'
    },
    '43113': {
      ETH: '0x0000000000000000000000000000000000000000',
      WETH: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
      BAL: '0xEE67880A6aaBa39c5eAf833B68Ea5fd908dC008D'
    },
    '43114': {
      ETH: '0x0000000000000000000000000000000000000000',
      WETH: '0xc579D1f3CF86749E05CD06f7ADe17856c2CE3126',
      BAL: '0x9FBA6AacB11010999355E60675A734278345B13C'
    },
    '106': {
      ETH: '0x0000000000000000000000000000000000000000',
      WETH: '0xc579D1f3CF86749E05CD06f7ADe17856c2CE3126',
      BAL: '0x117E0b609C7eEDafeF1A83Ad692dE52817A0B2F6'
    }
  },
  Prices: {
    ChainMap: {
      '42': {
        '0xdfcea9088c8a88a76ff74892c1457c17dfeef9c1':
          '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        '0x1c8e3bcb3378a443cc591f154c5ce0ebb4da9648':
          '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        '0x41286bb1d3e870f3f750eb7e1c25d7e48c8a1ac7':
          '0xba100000625a3754423978a60c9317c58a424e3d',
        '0x8f4bebf498cc624a0797fe64114a6ff169eee078':
          '0xbc396689893d065f41bc2c6ecbee5e0085233447',
        '0xaf9ac3235be96ed496db7969f60d354fe5e426b0':
          '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
        '0xc2569dd7d0fd715b054fbf16e75b001e5c0c1115':
          '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        '0x04df6e4121c27713ed22341e7c7df330f56f289b':
          '0x6b175474e89094c44da98b954eedeac495271d0f',
        '0x4803bb90d18a1cb7a2187344fe4feb0e07878d05':
          '0xae7ab96520de3a18e5e111b5eaab095312d7fe84'
      }
    },
    // TODO - remove once coingecko supports wstETH
    ExchangeRates: {
      wstETH: {
        stETH: 1.0352
      }
    }
  }
};
