
import { computed, defineComponent, PropType, ref } from 'vue';
import useNumbers from '@/composables/useNumbers';
import LiquidityAPRTooltip from '@/components/tooltips/LiquidityAPRTooltip.vue';
import useEthers from '@/composables/useEthers';
import {
  DecoratedFarm,
  DecoratedPoolWithFarm,
  DecoratedPoolWithRequiredFarm
} from '@/embr/services/subgraph/subgraph-types';
import useFarm from '@/embr/composables/farms/useFarm';
import useFarmUserQuery from '@/embr/composables/farms/useFarmUserQuery';
import FarmHarvestRewardsCard from '@/embr/components/pages/farm/FarmHarvestRewardsCard.vue';
import { DecoratedPool } from '@/services/balancer/subgraph/types';

export default defineComponent({
  components: {
    FarmHarvestRewardsCard,
    LiquidityAPRTooltip
  },

  props: {
    pool: {
      type: Object as PropType<DecoratedPoolWithRequiredFarm>,
      required: true
    }
  },

  setup(props) {
    const { fNum } = useNumbers();
    const { txListener } = useEthers();
    const { harvest } = useFarm(
      ref(props.pool.address),
      ref(props.pool.decoratedFarm.id)
    );
    const harvesting = ref(false);
    const farmUserQuery = useFarmUserQuery(props.pool.decoratedFarm.id);
    const farmUser = computed(() => farmUserQuery.data.value);

    async function harvestRewards(): Promise<void> {
      harvesting.value = true;
      const tx = await harvest();

      if (!tx) {
        harvesting.value = false;
        return;
      }

      txListener(tx, {
        onTxConfirmed: async () => {
          await farmUserQuery.refetch.value();
          harvesting.value = false;
        },
        onTxFailed: () => {
          harvesting.value = false;
        }
      });
    }

    // COMPUTED
    const stats = computed(() => {
      const farm = props.pool.decoratedFarm;
      const items = [
        {
          id: 'tvl',
          label: 'TVL',
          value: fNum(farm.tvl, 'usd')
        }
      ];

      items.push({
        id: 'embr',
        label: 'WAVE',
        value: `${fNum(farm.rewards, 'token_lg')} / day`
      });

      items.push({
        id: 'stake',
        label: 'My Balance',
        value: fNum(farm.stake, 'usd')
      });

      items.push({
        id: 'your_share',
        label: 'My Share',
        value: fNum(farm.share, 'percent')
      });

      return items;
    });

    const pendingRewards = computed(() => {
      return {
        count: farmUser.value?.pendingWAVE || 0,
        value: farmUser.value?.pendingWAVEValue || 0
      };
    });

    return {
      stats,
      pendingRewards,
      fNum,
      harvestRewards,
      harvesting
    };
  }
});
