
import { defineComponent } from 'vue';
import AppNavToggle from '@/embr/components/navs/AppNavToggle.vue';
import AppNavOtherItemsFooter from '@/embr/components/navs/AppNavOtherItemsFooter.vue';

export default defineComponent({
  name: 'AppFooterNav',

  components: {
    AppNavToggle,
    AppNavOtherItemsFooter
  }
});
