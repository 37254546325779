
import { defineComponent, ref } from 'vue';
import useNumbers from '@/composables/useNumbers';
import useEthers from '@/composables/useEthers';
import useFarm from '@/embr/composables/farms/useFarm';
import useFarmUser from '@/embr/composables/farms/useFarmUser';

export default defineComponent({
  name: 'FarmHarvestRewardsCard',

  components: {},

  props: {
    hasEmbrRewards: {
      type: Boolean,
      required: true
    },
    hasThirdPartyRewards: {
      type: Boolean,
      required: true
    },
    pendingEmbr: {
      type: Number,
      required: true
    },
    pendingEmbrValue: {
      type: Number,
      required: true
    },
    farmId: {
      type: String,
      required: true
    },
    tokenAddress: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const { fNum } = useNumbers();
    const { txListener } = useEthers();
    const { harvest } = useFarm(ref(props.tokenAddress), ref(props.farmId));
    const harvesting = ref(false);
    const { farmUserRefetch } = useFarmUser(props.farmId);

    async function harvestRewards(): Promise<void> {
      harvesting.value = true;
      const tx = await harvest();

      if (!tx) {
        harvesting.value = false;
        return;
      }

      txListener(tx, {
        onTxConfirmed: async () => {
          await farmUserRefetch.value();
          harvesting.value = false;
        },
        onTxFailed: () => {
          harvesting.value = false;
        }
      });
    }

    return {
      fNum,
      harvestRewards,
      harvesting
    };
  }
});
