import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-40730504")
const _hoisted_1 = { class: "token-select-input selected group selectable" }
const _hoisted_2 = { class: "text-base font-medium" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center justify-between"
}
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex items-center justify-between"
}
const _hoisted_8 = { class: "flex items-center" }
_popScopeId()

import { computed, ref, toRef } from 'vue';
// Types
import { FullPool } from '@/services/balancer/subgraph/types';
import { TokenInfo } from '@/types/TokenList';
// Composables
import useTokens from '@/composables/useTokens';
import useWithdrawalState from '../composables/useWithdrawalState';
import { usePool } from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  initToken?: string;
};

/**
 * Props
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    initToken: { type: String, required: false, default: 'all' }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  initToken?: string;
}) {

const props = __props


/**
 * STATE
 */
const selectedOption = ref(props.initToken);

/**
 * COMPOSABLES
 */
const { getTokens, getToken, nativeAsset } = useTokens();
const { isProportional, tokenOut } = useWithdrawalState(toRef(props, 'pool'));
const { isWethPool } = usePool(toRef(props, 'pool'));

/**
 * COMPUTED
 */
const tokenAddresses = computed(() => {
  if (isWethPool.value)
    return [nativeAsset.address, ...props.pool.tokenAddresses];
  return props.pool.tokenAddresses;
});

const tokens = computed(() => getTokens(tokenAddresses.value));

const options = computed(() => ['all', ...tokenAddresses.value]);

const selectedToken = computed((): TokenInfo => getToken(selectedOption.value));

const assetSetWidth = computed(
  () => 40 + (props.pool.tokenAddresses.length - 2) * 10
);

/**
 * METHODS
 */
function handleSelected(newToken: string): void {
  if (newToken === 'all') {
    isProportional.value = true;
    selectedOption.value = 'all';
  } else {
    isProportional.value = false;
    selectedOption.value = newToken;
    tokenOut.value = newToken;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalDropdown = _resolveComponent("BalDropdown")!

  return (_openBlock(), _createBlock(_component_BalDropdown, {
    options: _unref(options),
    minWidth: "44",
    onSelected: handleSelected
  }, {
    activator: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_unref(isProportional))
            ? (_openBlock(), _createBlock(_component_BalAssetSet, {
                key: 0,
                addresses: __props.pool.tokenAddresses,
                width: 50
              }, null, 8, ["addresses"]))
            : (_openBlock(), _createBlock(_component_BalAsset, {
                key: 1,
                address: _unref(selectedToken).address,
                class: "shadow mr-2"
              }, null, 8, ["address"]))
        ]),
        _createElementVNode("span", _hoisted_2, [
          (_unref(isProportional))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "All tokens"))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(selectedToken).symbol), 1))
        ]),
        _createVNode(_component_BalIcon, {
          name: "chevron-down",
          size: "sm",
          class: "text-white group-hover:text-gold-200 ml-2"
        })
      ])
    ]),
    option: _withCtx(({ option }) => [
      (option === 'all')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_BalAssetSet, {
                addresses: __props.pool.tokenAddresses,
                width: _unref(assetSetWidth)
              }, null, 8, ["addresses", "width"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('allTokens')), 1)
            ]),
            (selectedOption.value === option)
              ? (_openBlock(), _createBlock(_component_BalIcon, {
                  key: 0,
                  name: "check",
                  class: "text-white ml-2"
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_BalAsset, {
                address: option,
                class: "mr-2"
              }, null, 8, ["address"]),
              _createTextVNode(" " + _toDisplayString(_unref(tokens)[option]?.symbol), 1)
            ]),
            (selectedOption.value === option)
              ? (_openBlock(), _createBlock(_component_BalIcon, {
                  key: 0,
                  name: "check",
                  class: "text-white ml-2"
                }))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }, 8, ["options"]))
}
}

})