
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import useTokenLists from '@/composables/useTokenLists';
import PoolCreateTokenRow from '@/embr/components/pages/pool-create/PoolCreateTokenRow.vue';
import { getAddress } from '@ethersproject/address';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';
import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import { remove } from 'lodash';
import { PoolTokenInput } from '@/embr/services/pool/creator/pool-creator.service';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import PoolCreateDefinitionFields from '@/embr/components/pages/pool-create/PoolCreateDefinitionFields.vue';
import BalAlert from '@/components/_global/BalAlert/BalAlert.vue';
import { getTokensErrorFromInputs } from '@/embr/utils/poolCreateHelper';
import PoolCreateActions from '@/embr/components/pages/pool-create/PoolCreateActions.vue';
import PoolCreateSuccessOverlay from '@/embr/components/pages/pool-create/PoolCreateSuccessOverlay.vue';

export default defineComponent({
  components: {
    PoolCreateActions,
    PoolCreateDefinitionFields,
    BalIcon,
    BalBtn,
    BalCard,
    PoolCreateTokenRow,
    BalAlert,
    PoolCreateSuccessOverlay
  },

  setup() {
    const store = useStore();
    const { loadingTokenLists, tokenListsLoaded } = useTokenLists();
    const { appNetworkConfig } = useWeb3();
    const { tokens, balances, approvalRequired } = useTokens();
    const appLoading = computed(() => store.state.app.loading);
    const poolName = ref('');
    const poolSymbol = ref('');
    const swapFeePercentage = ref('0.25');
    const isDefinitionInputValid = ref(false);
    const editingDisabled = ref(false);
    const poolId = ref('');
    const poolCreateComplete = ref(false);
    const poolOwner = ref(appNetworkConfig.addresses.defaultPoolOwner);
    const approvedTokens = ref<string[]>([]);

    const poolTokens = ref<PoolTokenInput[]>([
      {
        address: getAddress(appNetworkConfig.addresses.weth),
        weight: '50',
        amount: ''
      },
      {
        address: getAddress(appNetworkConfig.addresses.usdc),
        weight: '50',
        amount: ''
      }
    ]);

    const tokensError = computed(() =>
      getTokensErrorFromInputs(
        poolTokens.value,
        tokens.value,
        balances.value,
        approvedTokens.value,
        approvalRequired
      )
    );

    const canCreatePool = computed(() => {
      return !tokensError.value && isDefinitionInputValid.value;
    });

    function addToken() {
      poolTokens.value.push({
        address: getAddress(appNetworkConfig.addresses.weth),
        weight: '50',
        amount: ''
      });
    }

    function deleteToken(idx: number) {
      remove(poolTokens.value, (token, index) => idx === index);
    }

    function tokenAddressChange(value: string, idx: number) {
      poolTokens.value[idx].address = value;
    }

    function tokenAmountChange(value: string, idx: number) {
      poolTokens.value[idx].amount = value;
    }

    function tokenWeightChange(value: string, idx: number) {
      poolTokens.value[idx].weight = value;
    }

    function handlePoolNameChange(value: string) {
      poolName.value = value;
    }

    function handlePoolSymbolChange(value: string) {
      poolSymbol.value = value.toUpperCase();
    }

    function handlePoolOwnerChange(value: string) {
      poolOwner.value = value;
    }

    function handleSwapFeePercentageChange(value: string) {
      swapFeePercentage.value = value;
    }

    function handleIsInputValid(valid: boolean) {
      isDefinitionInputValid.value = valid;
    }

    function handleTokenApproved(tokenAddress: string) {
      approvedTokens.value.push(tokenAddress);
    }

    function handlePoolCreateTriggered() {
      editingDisabled.value = true;
    }

    function handlePoolCreated(id: string) {
      editingDisabled.value = true;
      poolId.value = id;
    }

    function handlePoolCreateError() {
      editingDisabled.value = false;
    }

    function handlePoolVerified() {
      poolCreateComplete.value = true;
    }

    return {
      appLoading,
      loadingTokenLists,
      poolTokens,
      addToken,
      deleteToken,
      tokenAddressChange,
      tokenAmountChange,
      tokenWeightChange,
      poolName,
      poolSymbol,
      poolOwner,
      swapFeePercentage,
      handlePoolNameChange,
      handlePoolSymbolChange,
      handlePoolOwnerChange,
      tokenListsLoaded,
      handleSwapFeePercentageChange,
      handleIsInputValid,
      tokensError,
      canCreatePool,
      handleTokenApproved,
      handlePoolCreateTriggered,
      handlePoolCreated,
      handlePoolCreateError,
      handlePoolVerified,
      poolCreateComplete,
      editingDisabled,
      poolId
    };
  }
});
