import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"
import _imports_0 from '@/embr/assets/images/FarmAPR.png'


const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  width: "28"
}
const _hoisted_3 = { class: "text-sm divide-y dark:divide-gray-900" }
const _hoisted_4 = { class: "px-3 pt-3 pb-1 bg-gray-50 dark:bg-gray-800 rounded-t" }
const _hoisted_5 = { class: "text-gray-500" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = { class: "p-3" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "ml-1 text-gray-500 text-xs flex items-center" }
const _hoisted_10 = { class: "text-gray-500 text-xs ml-1" }
const _hoisted_11 = {
  key: 1,
  class: "whitespace-nowrap flex items-center mb-1"
}
const _hoisted_12 = { class: "ml-1 text-gray-500 text-xs" }

import { computed } from 'vue';
import useNumbers from '@/composables/useNumbers';
import { DecoratedPool } from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';

/**
 * TYPES
 */
type Props = {
  pool: DecoratedPool;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: DecoratedPool;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();

const hasThirdPartyAPR = computed(() =>
  bnum(props.pool.apr.thirdPartyApr).gt(0)
);

console.log("xploited", props.pool.apr)

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createBlock(_component_BalTooltip, {
    width: "auto",
    noPad: ""
  }, {
    activator: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.pool.apr.hasRewardApr)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createBlock(_component_BalIcon, _mergeProps({
              key: 1,
              name: "info",
              size: "sm",
              class: "text-gray-400 dark:text-gray-500"
            }, _ctx.$attrs), null, 16))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('totalAPR')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(fNum)(__props.pool.apr.total, 'percent')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.pool.apr.items, (item, idx) => {
            return (_openBlock(), _createElementBlock("div", { key: idx }, [
              (item.subItems && item.subItems.length > 0)
                ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                    key: 0,
                    items: item.subItems,
                    hideItems: false
                  }, _createSlots({
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createTextVNode(_toDisplayString(_unref(fNum)(item.apr, 'percent')) + " ", 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(item.title), 1)
                      ])
                    ]),
                    _: 2
                  }, [
                    true
                      ? {
                          name: "item",
                          fn: _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_unref(fNum)(item.apr, 'percent')) + " ", 1),
                            _createElementVNode("span", _hoisted_10, _toDisplayString(item.title), 1)
                          ])
                        }
                      : undefined
                  ]), 1032, ["items"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createTextVNode(_toDisplayString(_unref(fNum)(item.apr, 'percent')) + " ", 1),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(item.title), 1)
                  ]))
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})