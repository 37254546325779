import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalTextInput = _resolveComponent("BalTextInput")!
  const _component_BalForm = _resolveComponent("BalForm")!

  return (_openBlock(), _createBlock(_component_BalForm, {
    ref: "depositForm",
    onOnSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTextInput, {
        name: "Deposit",
        modelValue: _ctx.amount,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.amount = $event)),
        isValid: _ctx.validInput,
        "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => (_ctx.validInput = $event)),
        rules: _ctx.amountRules(),
        disabled: _ctx.loading,
        type: "number",
        min: "0",
        step: "any",
        placeholder: "0",
        "decimal-limit": 18,
        "validate-on": "input",
        "prepend-border": "",
        "append-shadow": ""
      }, {
        info: _withCtx(() => [
          _createElementVNode("div", {
            class: "cursor-pointer flex",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.amount = _ctx.bptBalance), ["prevent"]))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('balance')) + ": ", 1),
            (_ctx.dataLoading)
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-4 w-24 ml-1",
                  white: ""
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, " " + _toDisplayString(_ctx.bptBalance), 1))
          ])
        ]),
        append: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BalBtn, {
              size: "xs",
              color: "white",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.amount = _ctx.bptBalance), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('max')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "isValid", "rules", "disabled"]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isWalletReady)
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              label: _ctx.$t('connectWallet'),
              block: "",
              onClick: _withModifiers(_ctx.toggleWalletSelectModal, ["prevent"])
            }, null, 8, ["label", "onClick"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.approvalRequired)
                ? (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 0,
                    label: `Approve ${_ctx.tokenName ? _ctx.tokenName : 'WPT'}`,
                    loading: _ctx.approving,
                    "loading-label": _ctx.$t('approving'),
                    disabled: !_ctx.validInput || parseFloat(_ctx.amount) === 0 || _ctx.amount === '',
                    block: "",
                    onClick: _withModifiers(_ctx.approveToken, ["prevent"])
                  }, null, 8, ["label", "loading", "loading-label", "disabled", "onClick"]))
                : (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 1,
                    type: "submit",
                    "loading-label": _ctx.dataLoading ? 'Loading' : _ctx.$t('confirming'),
                    color: "gradient",
                    disabled: !_ctx.validInput || parseFloat(_ctx.amount) === 0 || _ctx.amount === '',
                    loading: _ctx.depositing || _ctx.dataLoading,
                    block: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickFarmDeposit)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Deposit " + _toDisplayString(_ctx.tokenName ? _ctx.tokenName : 'WPT'), 1)
                    ]),
                    _: 1
                  }, 8, ["loading-label", "disabled", "loading"]))
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onOnSubmit"]))
}