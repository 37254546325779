import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6d54026a")
const _hoisted_1 = { class: "p-2 px-3 border-b dark:border-gray-600" }
const _hoisted_2 = { class: "dark:text-gray-300" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "absolute top-0 left-0 right-0 bottom-0 w-4 mx-auto flex justify-center items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "font-bold"
}
_popScopeId()

import { computed } from 'vue';
import { getActiveClassName } from '@/components/utils';
import { StepState } from '@/types';

type Props = {
  title: string;
  steps: Step[];
};

type Step = {
  tooltip: string;
  state: StepState;
  label: number;
  isVisible?: boolean;
};


export default _defineComponent({
  props: {
    title: { type: String, required: true },
    steps: { type: Array, required: true }
  } as unknown as undefined,
  emits: ['navigate'],
  setup(__props: {
  title: string;
  steps: Step[];
}, { emit }) {

const props = __props



/**
 * COMPUTED
 */
const visibleSteps = computed(() => {
  return props.steps.filter(
    step => step.isVisible === undefined || step.isVisible
  );
});

const stepTextClasses = computed(() => {
  return visibleSteps.value.map(step => {
    return getActiveClassName(step.state, [
      [StepState.Active, 'text-blue-400 font-semibold hover:text-blue-800'],
      [StepState.Todo, 'text-gray-400 font-normal'],
      [StepState.Success, 'text-white font-semibold'],
      [StepState.Warning, 'text-red-500 font-semibold'],
      [StepState.Completed, 'text-gray-700 font-medium']
    ]);
  });
});

const stepCircleClasses = computed(() => {
  return visibleSteps.value.map(step => {
    return getActiveClassName(step.state, [
      [
        StepState.Active,
        'border-2 border-none bg-gradient-from-l bg-gradient-to-r from-blue-600 to-blue-50 text-white active'
      ],
      [
        StepState.Todo,
        'border-2 border-gray-300 dark:border-gray-600 text-gray-200'
      ],
      [
        StepState.Success,
        'border-2 border-none bg-gradient-to-tr from-green-500 to-green-200 text-white'
      ],
      [StepState.Warning, 'border-2 border-none bg-red-600 text-white active'],
      [StepState.Completed, 'border-2 border-gray-600 font-medium']
    ]);
  });
});

/**
 * FUNCTIONS
 */
function handleNavigate(state: StepState, stepIndex: number) {
  if (state === StepState.Todo) return;
  emit('navigate', stepIndex);
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(__props.title), 1)
      ]),
      _createVNode(_component_BalStack, {
        vertical: "",
        isDynamic: "",
        spacing: "base",
        class: "p-4",
        justify: "center"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleSteps), (step, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `vertical-step-${step.tooltip}`,
              class: "flex items-center"
            }, [
              _createElementVNode("button", {
                onClick: ($event: any) => (handleNavigate(step.state, i)),
                class: _normalizeClass({ 'cursor-default': step.state === _unref(StepState).Todo })
              }, [
                _createVNode(_component_BalStack, {
                  horizontal: "",
                  align: "center",
                  spacing: "sm"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                'relative text-sm rounded-full w-7 h-7 flex justify-center items-center',
                _unref(stepCircleClasses)[i],
                { 'circle-line': i !== _unref(visibleSteps).length - 1 }
              ])
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        (
                    ![_unref(StepState).Warning, _unref(StepState).Error].includes(step.state)
                  )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(step.label || i + 1), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_6, "!"))
                      ])
                    ], 2),
                    _createElementVNode("span", {
                      class: _normalizeClass(['text-sm', _unref(stepTextClasses)[i]])
                    }, _toDisplayString(step.tooltip), 3)
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_3)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})