import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ad760a48")
const _hoisted_1 = { class: "px-6 py-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "px-6 py-4 flex -mt-1 items-center flex-wrap" }
const _hoisted_4 = { class: "token-item" }
const _hoisted_5 = { class: "font-numeric" }
const _hoisted_6 = { class: "token-item" }
const _hoisted_7 = { class: "font-numeric" }
const _hoisted_8 = { class: "px-6 py-4 flex justify-end font-numeric" }
const _hoisted_9 = { class: "px-6 py-4" }
const _hoisted_10 = { class: "flex items-center justify-end wrap whitespace-nowrap text-right" }
_popScopeId()

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDistanceToNow } from 'date-fns';

import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useBreakpoints from '@/composables/useBreakpoints';

import { PoolSwap } from '@/services/balancer/subgraph/types';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';

import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type SwapRow = {
  tokenIn: string;
  tokenOut: string;
  tokenAmountIn: string;
  tokenAmountOut: string;
  timestamp: number;
  formattedDate: string;
  value: number;
  formattedValue: string;
  tx: string;
};

type Props = {
  tokens: string[];
  poolSwaps: PoolSwap[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokens: { type: Array, required: true },
    poolSwaps: { type: Array, required: true },
    isLoading: { type: Boolean, required: false, default: false },
    isLoadingMore: { type: Boolean, required: false, default: false },
    loadMore: { type: Function, required: false },
    isPaginated: { type: Boolean, required: false, default: false },
    noResultsLabel: { type: String, required: false }
  } as unknown as undefined,
  emits: ['loadMore'],
  setup(__props: {
  tokens: string[];
  poolSwaps: PoolSwap[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
}, { emit }) {

const props = __props




/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { t } = useI18n();
const { priceFor } = useTokens();
const { upToLargeBreakpoint } = useBreakpoints();
const { explorerLinks } = useWeb3();

/**
 * COMPUTED
 */
const columns = computed<ColumnDefinition<SwapRow>[]>(() => [
  {
    name: t('action'),
    id: 'action',
    accessor: 'tx',
    Cell: 'actionCell',
    width: 150,
    sortable: false
  },
  {
    name: t('details'),
    id: 'details',
    accessor: '',
    Cell: 'detailsCell',
    width: 325,
    sortable: false
  },
  {
    name: t('value'),
    id: 'value',
    accessor: 'value',
    Cell: 'valueCell',
    align: 'right',
    className: 'align-center w-40',
    sortKey: pool => pool.value,
    width: 125
  },
  {
    name: t('time'),
    id: 'timeAgo',
    accessor: 'timestamp',
    Cell: 'timeCell',
    align: 'right',
    sortKey: pool => pool.timestamp,
    width: 200
  }
]);

const swapRows = computed<SwapRow[]>(() =>
  props.isLoading
    ? []
    : props.poolSwaps.map(
        ({
          tokenIn,
          tokenOut,
          tokenAmountIn,
          tokenAmountOut,
          timestamp,
          tx
        }) => {
          const value = bnum(priceFor(tokenOut))
            .times(tokenAmountOut)
            .toNumber();

          return {
            value,
            formattedValue: value > 0 ? fNum(fNum(value, 'usd'), 'usd_m') : '-',
            tokenIn,
            tokenOut,
            tokenAmountIn,
            tokenAmountOut,
            timestamp,
            formattedDate: t('timeAgo', [formatDistanceToNow(timestamp)]),
            tx
          };
        }
      )
);

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    class: "overflow-x-auto",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: _unref(columns),
        data: _unref(swapRows),
        "is-loading": __props.isLoading,
        "is-loading-more": __props.isLoadingMore,
        "is-paginated": __props.isPaginated,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (emit('loadMore'))),
        "skeleton-class": "h-64",
        sticky: "both",
        "no-results-label": __props.noResultsLabel,
        "initial-state": {
        sortColumn: 'timeAgo',
        sortDirection: 'desc'
      }
      }, {
        actionCell: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('swap')), 1)
          ])
        ]),
        detailsCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BalAsset, {
                address: action.tokenIn,
                class: "mr-2 flex-shrink-0"
              }, null, 8, ["address"]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(fNum)(action.tokenAmountIn, 'token')), 1)
            ]),
            _createVNode(_component_BalIcon, {
              name: "arrow-right",
              class: "mx-1"
            }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_BalAsset, {
                address: action.tokenOut,
                class: "mr-2 flex-shrink-0"
              }, null, 8, ["address"]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(fNum)(action.tokenAmountOut, 'token')), 1)
            ])
          ])
        ]),
        valueCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_8, _toDisplayString(action.formattedValue), 1)
        ]),
        timeCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createTextVNode(_toDisplayString(action.formattedDate) + " ", 1),
              _createVNode(_component_BalLink, {
                href: _unref(explorerLinks).txLink(action.tx),
                external: "",
                class: "ml-2 flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "text-gray-200 hover:text-white transition-colors"
                  })
                ]),
                _: 2
              }, 1032, ["href"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "is-paginated", "no-results-label"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})