
import { defineComponent, ref, toRefs, watch } from 'vue';
import BalTextInput from '@/components/_global/BalTextInput/BalTextInput.vue';
import { isLessThanOrEqualTo, minChar } from '@/lib/utils/validations';
import {
  isGreaterThanOrEqualTo,
  isSymbol,
  isValidAddress,
  maxChar
} from '@/embr/utils/validations';

export default defineComponent({
  components: {
    BalTextInput
  },
  props: {
    swapFeePercentage: {
      type: String,
      required: true
    },
    poolOwner: {
      type: String,
      required: true
    },
    poolSymbol: {
      type: String,
      required: true
    },
    poolName: {
      type: String,
      required: true
    }
  },
  emits: [
    'poolNameChange',
    'poolSymbolChange',
    'poolOwnerChange',
    'poolSwapFeePercentageChange',
    'isInputValid'
  ],

  setup(props, { emit }) {
    const poolSymbolValid = ref(true);
    const poolOwnerValid = ref(true);
    const swapFeePercentageValid = ref(true);

    const { poolName, poolSymbol, swapFeePercentage } = toRefs(props);

    function handleIsValid() {
      const hasInput =
        props.swapFeePercentage !== '' &&
        props.poolSymbol !== '' &&
        props.poolName !== '';

      const isValid =
        poolOwnerValid.value &&
        poolOwnerValid.value &&
        swapFeePercentageValid.value;

      emit('isInputValid', hasInput && isValid);
    }

    watch(poolSymbolValid, () => {
      handleIsValid();
    });

    watch(poolSymbol, () => {
      handleIsValid();
    });

    watch(poolOwnerValid, () => {
      handleIsValid();
    });

    watch(swapFeePercentageValid, () => {
      handleIsValid();
    });

    watch(poolName, () => {
      handleIsValid();
    });

    watch(swapFeePercentage, () => {
      handleIsValid();
    });

    return {
      emit,
      maxChar,
      minChar,
      isGreaterThanOrEqualTo,
      isLessThanOrEqualTo,
      isValidAddress,
      isSymbol,
      poolSymbolValid,
      poolOwnerValid,
      swapFeePercentageValid,
      poolSymbolInfoText:
        'All WPT tokens start with the WPT- prefix and Metamask limits token symbols to 11 characters. So, your symbol can be no longer than 7 characters.',
      swapFeePercentageInfoText:
        'Ex: 0.25 = 0.25%. The swap fee percentage must be between 0.0001% and 10%',
      poolOwnerInfoText:
        'The pool owner is a multisig controlled by Wave. The only power the pool owner has is to change the swap fee percentage.'
    };
  }
});
