
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { EXTERNAL_LINKS } from '@/constants/links';
import useWeb3 from '@/services/web3/useWeb3';
import useEthers from '@/composables/useEthers';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';
import useNumbers from '@/composables/useNumbers';
import StepContainer from '@/embr/components/containers/StepContainer.vue';
import useTokenLists from '@/composables/useTokenLists';
import useTokens from '@/composables/useTokens';
import { getAddress } from '@ethersproject/address';
import useLgeCreateState from '@/embr/lbp/composables/useLgeCreateState';
import LbpProjectDetailInputs from '@/embr/lbp/components/LbpProjectDetailInputs.vue';
import LbpAuctionConfigurationInputs from '@/embr/lbp/components/LbpAuctionConfigurationInputs.vue';
import LbpCreateActions from '@/embr/lbp/components/LbpCreateActions.vue';
import LbpProjectDetailReview from '@/embr/lbp/components/LbpProjectDetailReview.vue';
import LbpAuctionConfigurationReview from '@/embr/lbp/components/LbpAuctionConfigurationReview.vue';
import LgeCreateMultisigWarning from '@/embr/lbp/components/LgeCreateMultisigWarning.vue';

export default defineComponent({
  components: {
    LgeCreateMultisigWarning,
    LbpAuctionConfigurationReview,
    LbpProjectDetailReview,
    LbpCreateActions,
    LbpAuctionConfigurationInputs,
    LbpProjectDetailInputs,
    StepContainer,
    BalBtn
  },

  setup() {
    // COMPOSABLES
    const router = useRouter();
    const { isWalletReady, appNetworkConfig } = useWeb3();
    const { txListener } = useEthers();
    const { fNum } = useNumbers();
    const { loadingTokenLists, tokenListsLoaded } = useTokenLists();
    const { dynamicDataLoaded } = useTokens();
    const {
      data,
      projectDetailsSaved,
      projectDetailsValid,
      saveProjectDetails,
      editProjectDetails,
      lgeConfigOpen,
      loadingToken,
      lgeConfigValid,
      saveLgeConfig,
      lgeConfigSaved,
      editLgeConfig,
      reviewAndDeployOpen,
      poolId,
      creatingLge,
      lgeSaved
    } = useLgeCreateState();

    const allowedCollateralTokens = computed(() => [
      getAddress(appNetworkConfig.addresses.weth),
      getAddress(appNetworkConfig.addresses.usdc)
    ]);

    const tokensLoaded = computed(() => {
      return tokenListsLoaded.value;
    });

    return {
      // data

      // computed
      isWalletReady,

      //methods
      router,
      // constants
      EXTERNAL_LINKS,
      fNum,
      tokenListsLoaded,
      dynamicDataLoaded,
      allowedCollateralTokens,
      tokensLoaded,
      data,
      saveProjectDetails,
      editProjectDetails,
      projectDetailsSaved,
      projectDetailsValid,
      lgeConfigOpen,
      loadingToken,
      lgeConfigValid,
      saveLgeConfig,
      lgeConfigSaved,
      editLgeConfig,
      reviewAndDeployOpen,
      poolId,
      creatingLge,
      lgeSaved
    };
  }
});
