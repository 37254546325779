
import { computed, defineComponent, PropType } from 'vue';
import numeral from 'numeral';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import { UserPoolData } from '@/embr/services/embr/embr-types';
import { orderBy } from 'lodash';
import useNumbers from '@/composables/useNumbers';

export default defineComponent({
  props: {
    pools: {
      type: Array as PropType<UserPoolData[]>,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  components: {
    BalCard
  },
  setup(props) {
    const { fNum } = useNumbers();

    const topPerformer = computed(() => {
      const sorted = orderBy(props.pools, 'priceChangePercent', 'desc');

      return sorted[0];
    });

    const topEarner = computed(() => {
      const sorted = orderBy(props.pools, 'myFees', 'desc');

      return sorted[0];
    });

    const highestVolume = computed(() => {
      const sorted = orderBy(props.pools, 'swapVolume', 'desc');

      return sorted[0];
    });

    const worstPerformer = computed(() => {
      const sorted = orderBy(props.pools, 'priceChangePercent', 'asc');

      return sorted[0];
    });

    const worstPerformerIsNegative = computed(() => {
      return worstPerformer.value
        ? worstPerformer.value.priceChange < 0
        : false;
    });

    const topPerformerIsNegative = computed(() => {
      return topPerformer.value ? topPerformer.value.priceChange < 0 : false;
    });

    function formatPriceChange(priceChange: number) {
      const sign = priceChange >= 0 ? '+' : '';
      return (
        sign +
        numeral(priceChange).format(
          Math.abs(priceChange) >= 10_000 ? '$0,0' : '$0,0.[00]'
        )
      );
    }

    function formatPriceChangePercent(priceChangePercent: number) {
      const sign = priceChangePercent >= 0 ? '+' : '';

      return sign + numeral(priceChangePercent).format('0,0.00%');
    }

    return {
      //refs

      numeral,
      fNum,
      topPerformer,
      topEarner,
      highestVolume,
      worstPerformer,
      worstPerformerIsNegative,
      topPerformerIsNegative,
      formatPriceChangePercent,
      formatPriceChange
    };
  }
});
