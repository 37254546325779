<template>
    <trading-vue :data="chart" :width="width" :height="height"
        title-txt="BTCUSD" ref="tvjs"
        :toolbar="true"
        :overlays="overlays"
        :color-back="colors.back"
        :color-grid="colors.grid"
        :color-text="colors.text"
        :color-title="colors.tvTitle">
    </trading-vue>
</template>
<script>

import { DataCube } from 'trading-vue-js'
// import Data from 'data.json'

export default {
    name: 'BalCandleStickChart',
    props: ['width', 'height', 'colors'],
  	mounted() {
      let self = this
      this.$nextTick(() => {
        self.$refs.tvjs.data.set("chart.data", [
          [ 16307430000, 22.0625, 22.5, 21.4375, 21.6875, 82.4132 ],
          [ 16308870000, 21.59375, 24.4375, 21.5625, 23.96875, 135.9622 ],
        ]);
        // debugger;
        self.$refs.tvjs.setRange(16307430000, 16308870000)
      })
    },
    data() {
        return {
            chart: new DataCube({
              chart: {
                indexBased: true,
                tf: "1h",
                data: []
              }
            }),
            overlays: []
        }
    }
}
</script>
<style>
</style>