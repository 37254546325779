import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 gap-4" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-4" }
const _hoisted_3 = { class: "text-sm text-gray-200 font-medium mb-2" }
const _hoisted_4 = { class: "text-xl font-medium truncate flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LiquidityAPRTooltip = _resolveComponent("LiquidityAPRTooltip")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_FarmHarvestRewardsCard = _resolveComponent("FarmHarvestRewardsCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat, i) => {
        return (_openBlock(), _createBlock(_component_BalCard, { key: i }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(stat.label), 1),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(stat.value) + " ", 1),
              (stat.id === 'apr')
                ? (_openBlock(), _createBlock(_component_LiquidityAPRTooltip, {
                    key: 0,
                    pool: _ctx.pool
                  }, null, 8, ["pool"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _createVNode(_component_FarmHarvestRewardsCard, {
      "farm-id": _ctx.pool.decoratedFarm.id,
      "token-address": _ctx.pool.address,
      "has-embr-rewards": _ctx.pool.decoratedFarm.rewards > 0,
      "has-third-party-rewards": _ctx.pool.decoratedFarm.rewardTokenPerDay > 0,
      "pending-embr": _ctx.pool.decoratedFarm.pendingWAVE,
      "pending-embr-value": _ctx.pool.decoratedFarm.pendingWAVEValue
    }, null, 8, ["farm-id", "token-address", "has-embr-rewards", "has-third-party-rewards", "pending-embr", "pending-embr-value"])
  ]))
}