import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2c93ab23")
const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = { class: "toggle-container" }
const _hoisted_3 = { class: "dark:text-white" }
_popScopeId()

import { computed, ref } from 'vue';
import * as echarts from 'echarts/core';
import useNumbers from '@/composables/useNumbers';
import useTailwind from '@/composables/useTailwind';
import { format, fromUnixTime } from 'date-fns';
import numeral from 'numeral';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import ECharts from 'vue-echarts';
import BalBlankSlate from '@/components/_global/BalBlankSlate/BalBlankSlate.vue';
import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import { GqlBalancerPoolSnapshot } from '@/embr/services/embr/embr-types';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';
import { capitalize } from 'lodash';

type Props = {
  snapshots: GqlBalancerPoolSnapshot[];
};

/**
 * PROPS
 */
type ChartType = 'volume' | 'tvl' | 'fees';

export default _defineComponent({
  props: {
    snapshots: { type: Array, required: true }
  } as unknown as undefined,
  setup(__props: {
  snapshots: GqlBalancerPoolSnapshot[];
}) {

const props = __props


const chartInstance = ref<echarts.ECharts>();
const { fNum } = useNumbers();
const tailwind = useTailwind();
const currentChartType = ref<ChartType>('volume');

const chartLabel = computed(() => {
  switch (currentChartType.value) {
    case 'volume':
      return 'Volume';
    case 'fees':
      return 'Fees';
    case 'tvl':
      return 'TVL';
  }

  return '';
});

const chartConfig = computed(() => {
  return {
    color: '#9706FA',
    tooltip: {
      trigger: 'axis',
      backgroundColor: tailwind.theme.colors.gray['800'],
      borderColor: tailwind.theme.colors.gray['800'],
      formatter: params => {
        return `
            <div class='flex flex-col font-body bg-white dark:bg-gray-800 dark:text-white'>
              ${params
                .map(
                  param => `
                <div class="flex items-center">
                ${param.marker} <div class="flex-1">${
                    param.seriesName
                  }</div> <div class='font-medium ml-4'>${fNum(
                    param.value,
                    'usd'
                  )}
                  </div>
                </div>
              `
                )
                .join('')}
            </div>
          `;
      },
      axisPointer: {
        type: 'shadow'
      }
    },
    width: '100%',
    grid: {
      left: 0,
      right: '2.5%',
      top: '4%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: props.snapshots.map(snapshot =>
          format(fromUnixTime(snapshot.timestamp), 'MMM. d')
        ),
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: {
      type: 'value',
      axisLine: {
        onZero: false,
        lineStyle: { color: tailwind.theme.colors.gray['600'] }
      },
      axisLabel: {
        color: tailwind.theme.colors.gold[300],
        fontSize: 14,
        formatter: value =>
          `$${numeral(value).format(value > 1000 ? '0a' : '0.[00]')}`
      },
      splitLine: {
        lineStyle: {
          color: tailwind.theme.colors.gray['700']
        }
      }
    },
    series: [
      {
        name: chartLabel.value,
        type: 'bar',
        barWidth: '80%',
        data: props.snapshots.map(snapshot => {
          switch (currentChartType.value) {
            case 'volume':
              return parseFloat(snapshot.swapVolume24h);
            case 'fees':
              return parseFloat(snapshot.swapFees24h);
            case 'tvl':
              return parseFloat(snapshot.totalLiquidity);
          }
        }),
        itemStyle: { borderRadius: 4 }
      }
    ]
  };
});

function setCurrentChart(chartType: ChartType) {
  currentChartType.value = chartType;
  //
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BalCard, {
    "no-content-pad": "",
    class: "px-4 pt-4"
  }, {
    default: _withCtx(() => [
      (props.snapshots.length > 2)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass([
              'toggle-button',
              currentChartType.value === 'volume' ? 'toggle-button-active' : ''
            ]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (setCurrentChart('volume')))
                }, " Volume ", 2),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'toggle-button',
              currentChartType.value === 'tvl' ? 'toggle-button-active' : ''
            ]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (setCurrentChart('tvl')))
                }, " TVL ", 2),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'toggle-button',
              currentChartType.value === 'fees' ? 'toggle-button-active' : ''
            ]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (setCurrentChart('fees')))
                }, " Fees ", 2)
              ])
            ]),
            _createVNode(_unref(ECharts), {
              ref: chartInstance,
              class: "w-full h-96",
              option: _unref(chartConfig),
              autoresize: "",
              "update-options": { replaceMerge: 'series' }
            }, null, 8, ["option"])
          ], 64))
        : (_openBlock(), _createBlock(BalBlankSlate, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(BalIcon, { name: "bar-chart" }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('insufficientData')), 1)
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}
}

})