import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-56393f7c")
const _hoisted_1 = { class: "lbp-input-row" }
const _hoisted_2 = { class: "lbp-input-row" }
const _hoisted_3 = { class: "lbp-input-row" }
const _hoisted_4 = { class: "lbp-input-row" }
const _hoisted_5 = { class: "mb-8" }
_popScopeId()

import useLgeCreateState from '@/embr/lbp/composables/useLgeCreateState';
import BalTextInput from '@/components/_global/BalTextInput/BalTextInput.vue';


export default _defineComponent({
  setup(__props) {

const { data } = useLgeCreateState();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BalTextInput, {
        title: "Project Name*",
        modelValue: _unref(data).name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(data).name = $event)),
        type: "text"
      }, null, 8, ["modelValue"]),
      _createVNode(BalTextInput, {
        title: "Project Website URL*",
        modelValue: _unref(data).websiteUrl,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(data).websiteUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BalTextInput, {
        title: "Token Contract Address*",
        modelValue: _unref(data).tokenContractAddress,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_unref(data).tokenContractAddress = $event)),
        type: "text"
      }, null, 8, ["modelValue"]),
      _createVNode(BalTextInput, {
        title: "Token Icon URL*",
        modelValue: _unref(data).tokenIconUrl,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_unref(data).tokenIconUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(BalTextInput, {
        title: "Twitter URL",
        modelValue: _unref(data).twitterUrl,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_unref(data).twitterUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"]),
      _createVNode(BalTextInput, {
        title: "Medium URL",
        modelValue: _unref(data).mediumUrl,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_unref(data).mediumUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(BalTextInput, {
        title: "Discord URL",
        modelValue: _unref(data).discordUrl,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_unref(data).discordUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"]),
      _createVNode(BalTextInput, {
        title: "Telegram URL",
        modelValue: _unref(data).telegramUrl,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_unref(data).telegramUrl = $event)),
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(BalTextInput, {
        title: "Banner Image URL",
        modelValue: _unref(data).bannerImageUrl,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_unref(data).bannerImageUrl = $event)),
        tooltip: "Optionally provide a banner image that will appear on your auction page.",
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(BalTextInput, {
        title: "Project Description*",
        modelValue: _unref(data).description,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_unref(data).description = $event)),
        type: "textarea"
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}
}

})