import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = { class: "font-medium text-gray-400 ml-1" }

import useNumbers from '@/composables/useNumbers';
import BalAsset from '@/components/_global/BalAsset/BalAsset.vue';

type Props = {
  address: string;
  symbol: string;
  weight: number | string;
  iconURI?: string;
};


export default _defineComponent({
  props: {
    address: { type: String, required: true },
    symbol: { type: String, required: true },
    weight: { type: [Number, String], required: true },
    iconURI: { type: String, required: false }
  } as unknown as undefined,
  setup(__props: {
  address: string;
  symbol: string;
  weight: number | string;
  iconURI?: string;
}) {

const props = __props


const { fNum } = useNumbers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BalAsset, {
      address: props.address,
      iconURI: props.iconURI
    }, null, 8, ["address", "iconURI"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(props.symbol), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(fNum)(props.weight, 'percent_lg')), 1)
  ]))
}
}

})