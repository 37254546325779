import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps({
      ref: "activator",
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
      class: ['leading-none', { 'cursor-default': _ctx.disabled }]
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "activator", {}, () => [
        _createVNode(_component_BalIcon, {
          name: "info",
          size: _ctx.iconSize,
          class: "text-gray-300"
        }, null, 8, ["size"])
      ])
    ], 16),
    _createElementVNode("div", _mergeProps({
      ref: "content",
      class: ["tooltip text-xs text-black dark:text-white bg-white dark:bg-gray-800 font-medium shadow rounded-md border dark:border-gray-900 z-50", _ctx.tooltipClasses]
    }, _ctx.$attrs, {
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnterTooltip && _ctx.handleMouseEnterTooltip(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
    }), [
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            textContent: _toDisplayString(_ctx.text)
          }, null, 8, _hoisted_1))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 16)
  ], 64))
}