import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "border dark:border-gray-700 rounded-lg p-2 pb-2 mt-4"
}
const _hoisted_2 = { class: "mt-4" }

import { toRef, computed, ref, onBeforeMount } from 'vue';
import { FullPool } from '@/services/balancer/subgraph/types';
import { isLessThanOrEqualTo, isRequired } from '@/lib/utils/validations';
// Composables
import useWithdrawMath from './composables/useWithdrawMath';
import useWithdrawalState from './composables/useWithdrawalState';
import useWeb3 from '@/services/web3/useWeb3';
import { useI18n } from 'vue-i18n';
// Components
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import WithdrawTotals from './components/WithdrawTotals.vue';
import WithdrawPreviewModal from './components/WithdrawPreviewModal/WithdrawPreviewModal.vue';
import ProportionalWithdrawalInput from './components/ProportionalWithdrawalInput.vue';
import WithdrawalTokenSelect from './components/WithdrawalTokenSelect.vue';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
}) {

const props = __props


const showPreview = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

const {
  isProportional,
  tokenOut,
  tokenOutIndex,
  highPriceImpactAccepted,
  validInput,
  maxSlider,
  tokensOut
} = useWithdrawalState(toRef(props, 'pool'));

const withdrawMath = useWithdrawMath(
  toRef(props, 'pool'),
  isProportional,
  tokenOut,
  tokenOutIndex
);

const {
  hasAmounts,
  highPriceImpact,
  singleAssetMaxes,
  tokenOutAmount,
  tokenOutPoolBalance,
  initMath,
  loadingAmountsOut
} = withdrawMath;

const {
  isWalletReady,
  toggleWalletSelectModal,
  isMismatchedNetwork
} = useWeb3();

/**
 * COMPUTED
 */
const hasAcceptedHighPriceImpact = computed((): boolean =>
  highPriceImpact.value ? highPriceImpactAccepted.value : true
);

const hasValidInputs = computed(
  (): boolean => validInput.value && hasAcceptedHighPriceImpact.value
);

const singleAssetRules = computed(() => [
  isLessThanOrEqualTo(tokenOutPoolBalance.value, t('exceedsPoolBalance'))
]);

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  isProportional.value = true;
  maxSlider();
  initMath();
});

return (_ctx: any,_cache: any) => {
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isProportional))
      ? (_openBlock(), _createBlock(ProportionalWithdrawalInput, {
          key: 0,
          pool: __props.pool,
          tokenAddresses: _unref(tokensOut),
          math: _unref(withdrawMath)
        }, null, 8, ["pool", "tokenAddresses", "math"]))
      : (_openBlock(), _createBlock(TokenInput, {
          key: 1,
          name: _unref(tokenOut),
          address: _unref(tokenOut),
          amount: _unref(tokenOutAmount),
          "onUpdate:amount": _cache[0] || (_cache[0] = ($event: any) => (_isRef(tokenOutAmount) ? tokenOutAmount.value = $event : null)),
          isValid: _unref(validInput),
          "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => (_isRef(validInput) ? validInput.value = $event : null)),
          customBalance: _unref(singleAssetMaxes)[_unref(tokenOutIndex)] || '0',
          rules: _unref(singleAssetRules),
          balanceLabel: _ctx.$t('singleTokenMax'),
          balanceLoading: _unref(loadingAmountsOut),
          fixedToken: "",
          disableNativeAssetBuffer: ""
        }, {
          tokenSelect: _withCtx(() => [
            _createVNode(WithdrawalTokenSelect, {
              pool: __props.pool,
              initToken: _unref(tokenOut)
            }, null, 8, ["pool", "initToken"])
          ]),
          _: 1
        }, 8, ["name", "address", "amount", "isValid", "customBalance", "rules", "balanceLabel", "balanceLoading"])),
    _createVNode(WithdrawTotals, {
      math: _unref(withdrawMath),
      class: "mt-4"
    }, null, 8, ["math"]),
    (_unref(highPriceImpact))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BalCheckbox, {
            modelValue: _unref(highPriceImpactAccepted),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(highPriceImpactAccepted) ? highPriceImpactAccepted.value = $event : null)),
            rules: [_unref(isRequired)(_ctx.$t('priceImpactCheckbox'))],
            name: "highPriceImpactAccepted",
            size: "sm",
            label: _ctx.$t('priceImpactAccept', [_ctx.$t('withdrawing')])
          }, null, 8, ["modelValue", "rules", "label"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_unref(isWalletReady))
        ? (_openBlock(), _createBlock(_component_BalBtn, {
            key: 0,
            label: _ctx.$t('connectWallet'),
            color: "gradient",
            block: "",
            onClick: _unref(toggleWalletSelectModal)
          }, null, 8, ["label", "onClick"]))
        : (_openBlock(), _createBlock(_component_BalBtn, {
            key: 1,
            label: _ctx.$t('preview'),
            color: "gradient",
            disabled: 
          !_unref(hasAmounts) ||
            !_unref(hasValidInputs) ||
            _unref(isMismatchedNetwork) ||
            _unref(loadingAmountsOut)
        ,
            block: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (showPreview.value = true))
          }, null, 8, ["label", "disabled"]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showPreview.value)
        ? (_openBlock(), _createBlock(WithdrawPreviewModal, {
            key: 0,
            pool: __props.pool,
            math: _unref(withdrawMath),
            onClose: _cache[4] || (_cache[4] = ($event: any) => (showPreview.value = false))
          }, null, 8, ["pool", "math"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})