
import { computed, defineComponent, PropType, ref } from 'vue';
import useNumbers from '@/composables/useNumbers';
import { sumBy, groupBy, map } from 'lodash';
import numeral from 'numeral';
import usePools from '@/composables/pools/usePools';
import useEthers from '@/composables/useEthers';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import { Alert } from '@/composables/useAlerts';
//import { useXEmbr } from '@/embr/composables/stake/useXEmbr';

export default defineComponent({
  name: 'AppNavClaimBtn',

  props: {
    alert: { type: Object as PropType<Alert>, required: true }
  },

  setup(props) {
    const { isWalletReady, appNetworkConfig } = useWeb3();
    const { txListener } = useEthers();
    const { fNum } = useNumbers();
    const {
      isLoadingPools,
      isLoadingFarms,
      onlyPoolsWithFarms,
      harvestAllFarms,
      refetchFarmsForUser
    } = usePools();
    const harvesting = ref(false);
    const { upToLargeBreakpoint } = useBreakpoints();
    //const { xembrDecoratedFarm } = useXEmbr();

    const data = computed(() => {
      const farms = onlyPoolsWithFarms.value.map(pool => pool.decoratedFarm);
      const pendingWAVEValue = sumBy(farms, farm => farm.pendingWAVEValue);


      console.log("get data about farms", farms, pendingWAVEValue)
      const averageApr =
        sumBy(farms, farm => farm.apr * (farm.stake || 0)) /
        sumBy(farms, farm => farm.stake || 0);

      return {
        numFarms: farms.filter(farm => farm.stake > 0).length,
        totalBalance: fNum(
          sumBy(farms, farm => farm.stake || 0),
          'usd'
        ),
        pendingRewardValue: fNum(
          pendingWAVEValue,
          'usd'
        ),
        pendingWAVE:
          numeral(sumBy(farms, farm => farm.pendingWAVE)).format(
            '0,0.[0000]'
          ) + ' WAVE',
        apr: fNum(averageApr, 'percent'),
        dailyApr: fNum(averageApr / 365, 'percent')
      };
    });

    const hasFarmRewards = computed(
      () =>
        onlyPoolsWithFarms.value.filter(pool => pool.decoratedFarm.stake > 0)
          .length > 0
    );

    async function harvestAllRewards(): Promise<void> {
      const farmIds = onlyPoolsWithFarms.value
        .filter(pool => pool.decoratedFarm.stake > 0)
        .map(pool => pool.decoratedFarm.id);

      harvesting.value = true;
      const tx = await harvestAllFarms(farmIds);

      if (!tx) {
        harvesting.value = false;
        return;
      }

      txListener(tx, {
        onTxConfirmed: async () => {
          await refetchFarmsForUser();
          harvesting.value = false;
        },
        onTxFailed: () => {
          harvesting.value = false;
        }
      });
    }

    return {
      data,
      hasFarmRewards,
      fNum,
      harvestAllRewards,
      harvesting,
      upToLargeBreakpoint,
      isLoadingPools,
      isLoadingFarms
    };
  }
});
