
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PoolCreateSuccessOverlay',

  props: {
    poolId: { type: String, required: true }
  },

  setup(props) {
    const router = useRouter();

    function navigateToPool() {
      router.push({
        name: 'pool',
        params: {
          id: props.poolId
        }
      });
    }

    return {
      navigateToPool
    };
  }
});
