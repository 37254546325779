
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import useTokenLists from '@/composables/useTokenLists';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';
import BalIcon from '@/components/_global/BalIcon/BalIcon.vue';
import {
  PoolCreatorService,
  PoolTokenInput
} from '@/embr/services/pool/creator/pool-creator.service';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import useEthers from '@/composables/useEthers';
import useTransactions from '@/composables/useTransactions';
import PoolCreateConfirmModal from '@/embr/components/pages/pool-create/PoolCreateConfirmModal.vue';
import { TransactionReceipt } from '@ethersproject/providers';

export default defineComponent({
  components: {
    PoolCreateConfirmModal,
    BalIcon,
    BalBtn,
    BalCard
  },
  props: {
    swapFeePercentage: {
      type: String,
      required: true
    },
    // poolOwner: {
    //   type: String,
    //   required: true
    // },
    poolSymbol: {
      type: String,
      required: true
    },
    poolName: {
      type: String,
      required: true
    },
    poolTokens: {
      type: Array as PropType<PoolTokenInput[]>,
      required: true
    },
    canCreatePool: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'poolNameChange',
    'poolSymbolChange',
    // 'poolOwnerChange',
    'poolSwapFeePercentageChange',
    'isInputValid',
    'createPoolTriggered',
    'createPoolError',
    'createdPool',
    'verifiedPool'
  ],

  setup(props, { emit }) {
    const store = useStore();
    const { loadingTokenLists, tokenListsLoaded } = useTokenLists();
    const { appNetworkConfig, getProvider, account } = useWeb3();
    const { tokens } = useTokens();
    const { txListener } = useEthers();
    const { addTransaction } = useTransactions();
    const appLoading = computed(() => store.state.app.loading);
    const poolCreatorService = computed(
      () => new PoolCreatorService(appNetworkConfig.key)
    );

    const verifying = ref(false);
    const verified = ref(false);
    const creating = ref(false);
    const joining = ref(false);
    const joined = ref(false);
    const createModalVisible = ref(false);
    const blockHash = ref('');
    const poolAddress = ref('');
    const poolId = ref('');

    async function createPool(): Promise<void> {
      createModalVisible.value = false;

      const {
        poolName,
        poolSymbol,
        // poolOwner,
        swapFeePercentage,
        poolTokens
      } = props;

      try {
        emit('createPoolTriggered');
        creating.value = true;
        const tx = await poolCreatorService.value.createWeightedPool(
          getProvider(),
          props.poolName,
          `WPT-${poolSymbol}`,
          // "0x59AfAF89723614Cbf303D3D4CbABa2c7280Fe563",
          swapFeePercentage,
          poolTokens
        );
    
        addTransaction({
          id: tx.hash,
          type: 'tx',
          action: 'create',
          summary: `Creating your pool with name: ${poolName}`,
          details: { name: props.poolName, symbol: poolSymbol }
        });

        txListener(tx, {
          onTxConfirmed: async (receipt: TransactionReceipt) => {
            const data = await poolCreatorService.value.getPoolDataFromTransaction(
              getProvider(),
              receipt
            );
            poolAddress.value = data.poolAddress;
            blockHash.value = data.blockHash;
            poolId.value = data.poolId;

            emit('createdPool', data.poolId);
            creating.value = false;
          },
          onTxFailed: () => {
            creating.value = false;
            emit('createPoolError');
          }
        });
      } catch (error) {
        console.error(error);
        creating.value = false;
        emit('createPoolError');
      }
    }

    async function joinPool(): Promise<void> {
      try {
        joining.value = true;
        const tx = await poolCreatorService.value.joinPool(
          getProvider(),
          props.poolTokens,
          poolId.value,
          account.value,
          tokens.value
        );

        addTransaction({
          id: tx.hash,
          type: 'tx',
          action: 'invest',
          summary: `Joining your pool with name: ${props.poolName}`,
          details: {}
        });

        txListener(tx, {
          onTxConfirmed: async () => {
            joining.value = false;
            joined.value = true;
            emit('verifiedPool');
          },
          onTxFailed: () => {
            joining.value = false;
          }
        });
      } catch (error) {
        console.error(error);
        joining.value = false;
      }
    }

    async function verifyPool() {
      const {
        poolName,
        poolSymbol,
        // poolOwner,
        swapFeePercentage,
        poolTokens
      } = props;

      try {
        verifying.value = true;
        await poolCreatorService.value.verifyPool(
          getProvider(),
          poolName,
          `WPT-${poolSymbol}`,
          "0x59AfAF89723614Cbf303D3D4CbABa2c7280Fe563",
          swapFeePercentage,
          poolTokens,
          poolAddress.value,
          blockHash.value
        );

        verifying.value = false;
        verified.value = true;
        //emit('verifiedPool');
      } catch {
        verifying.value = false;
      }
    }

    return {
      appLoading,
      loadingTokenLists,
      tokenListsLoaded,
      createPool,
      joinPool,
      poolAddress,
      poolId,
      blockHash,
      creating,
      joining,
      joined,
      verifyPool,
      verifying,
      verified,
      createModalVisible
    };
  }
});
