import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex items-center flex-wrap" }
const _hoisted_2 = { class: "flex items-center flex-wrap" }
const _hoisted_3 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalChip = _resolveComponent("BalChip")!
  const _component_SelectTokenModal = _resolveComponent("SelectTokenModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BalBtn, {
          color: "white",
          size: "sm",
          onClick: _ctx.onClick,
          class: "mr-4 bal-tab-wave-not-selected",
          style: {"border-radius":"9999px !important"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "search",
              size: "sm",
              class: "mr-2"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('filterByToken')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (token) => {
          return (_openBlock(), _createBlock(_component_BalChip, {
            class: "mr-2",
            key: token,
            color: "white",
            iconSize: "sm",
            closeable: true,
            onClosed: ($event: any) => (_ctx.$emit('remove', token))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalAsset, {
                address: token,
                size: 20,
                class: "flex-auto"
              }, null, 8, ["address"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.tokens[token]?.symbol), 1)
            ]),
            _: 2
          }, 1032, ["onClosed"]))
        }), 128))
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.selectTokenModal)
        ? (_openBlock(), _createBlock(_component_SelectTokenModal, {
            key: 0,
            "excluded-tokens": _ctx.modelValue,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTokenModal = false)),
            onSelect: _ctx.addToken
          }, null, 8, ["excluded-tokens", "onSelect"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}