import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 grid-cols-2 lg:grid-cols-4 gap-y-8 gap-x-4 mb-12" }
const _hoisted_2 = { class: "text-gray-200 font-medium mb-4" }
const _hoisted_3 = { class: "text-xl font-medium truncate flex items-center mt-4" }
const _hoisted_4 = { class: "text-sm text-gray-200" }

import { FullPool } from '@/services/balancer/subgraph/types';
import BalCard from '@/components/_global/BalCard/BalCard.vue';
import BalAssetSet from '@/components/_global/BalAsset/BalAssetSet.vue';
import BalLoadingBlock from '@/components/_global/BalLoadingBlock/BalLoadingBlock.vue';
import LiquidityAPRTooltip from '@/components/tooltips/LiquidityAPRTooltip.vue';
import useNumbers from '@/composables/useNumbers';
import { useRouter } from 'vue-router';

type Props = {
  pools: FullPool[];
  isLoading: boolean;
};


export default _defineComponent({
  props: {
    pools: { type: Array, required: true },
    isLoading: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pools: FullPool[];
  isLoading: boolean;
}) {

const props = __props
const router = useRouter();


const { fNum } = useNumbers();

function navigateToPool(pool: FullPool) {
  router.push({
    name: 'pool',
    params: { id: pool.id }
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (n) => {
          return _createVNode(BalLoadingBlock, {
            key: n,
            class: "h-52"
          })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(props.pools, (pool) => {
          return (_openBlock(), _createBlock(BalCard, {
            key: pool.id,
            class: "col col-span-1 cursor-pointer bal-card-special",
            onClick: ($event: any) => (navigateToPool(pool))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(pool.name), 1),
              _createVNode(BalAssetSet, {
                addresses: pool.tokens.map(token => token.address),
                size: 40,
                width: 150
              }, null, 8, ["addresses"]),
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_unref(fNum)(pool.apr.total, 'percent')) + " APR ", 1),
                _createVNode(LiquidityAPRTooltip, { pool: pool }, null, 8, ["pool"])
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum)(pool.apr.total / 365, 'percent')) + " DAILY ", 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
  ]))
}
}

})